import { useUser } from '../redux/user/selectors';
import localeDate from '../services/localeDate';

const useLocaleDate = () => {
  const [user, loading] = useUser();

  if (loading) return localeDate.en;

  return localeDate[user.language];
};

export default useLocaleDate;
