import HKSMOsteps from '../components/workflow/HKSMO/HKSMO.steps';
import SMOSTEPS from '../components/workflow/SMO/SMO.steps';
import { useMedicalFile } from '../redux/medicalFile/selectors';
import { useStep } from '../redux/step/selectors';

const allSteps = {
  0: SMOSTEPS,
  1: HKSMOsteps,
};

const useStepActive = () => {
  const [step] = useStep();
  const [medicalFile] = useMedicalFile();

  const steps = Object.values(allSteps[medicalFile.type]);

  const stepActive = steps.findIndex((s) => s === step?.reference);

  const beforeStep = (reference) => {
    const findIndex = steps.findIndex((s) => s === reference);
    return stepActive <= findIndex;
  };

  const isStep = (reference) => {
    const findIndex = steps.findIndex((s) => s === reference);
    return stepActive === findIndex;
  };

  const afterStep = (reference) => {
    const findIndex = steps.findIndex((s) => s === reference);
    return stepActive >= findIndex;
  };

  return { beforeStep, isStep, afterStep };
};

export default useStepActive;
