/* eslint-disable no-underscore-dangle */
/* eslint-disable camelcase */
/* eslint-disable react/prop-types */
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { Button, Descriptions } from 'antd';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import Flex from '../../components/Flex';
import useHasPermission from '../../hooks/useHasPermission';
import { cancelMedicalFile } from '../../redux/medicalFile/actions';
import { usePathology } from '../../redux/metadata/selectors';
import { useRoles } from '../../redux/user/selectors';
import { isExpertCoordinator } from '../../services/rights';

function MedicalFileDetails({ medicalFile }) {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const hasPermission = useHasPermission();
  const roles = useRoles();
  const pathologies = usePathology();
  const [interviewEnd, setInterviewEnd] = useState(new Date(medicalFile.interview));
  const location = useLocation();
  const [onEdit, setOnEdit] = useState(location.state?.edit);

  const { interlocutors, reference, step } = medicalFile;

  const findInterlocutor = (type) => interlocutors.find((i) => i.type === type);

  const lc = findInterlocutor('Local Coordinator');
  const ld = findInterlocutor('Local Doctor');
  const hc = findInterlocutor('Hub Coordinator');
  const hd = findInterlocutor('Hub Doctor');
  const ec = findInterlocutor('Expert Coordinator');
  const ed = findInterlocutor('Expert Doctor');

  useEffect(() => {
    const _interviewEnd = new Date(medicalFile.interview);
    _interviewEnd.setHours(_interviewEnd.getHours() + 2);
    setInterviewEnd(_interviewEnd);
  }, [medicalFile]);

  useEffect(() => {
    if (onEdit) {
      history.push(`/medicals/${reference}/edit`);
    }
  }, [onEdit, history, reference]);

  return (
    <>
      <Descriptions bordered>
        <Descriptions.Item label={t('FRONT_MEDICAL_FILE_REFERENCE')} span={3}>
          {reference}
        </Descriptions.Item>
        {medicalFile.internal_reference_local && (
          <Descriptions.Item label={t('FRONT_MEDICAL_FILE_LOCAL_REFERENCE')} span={3}>
            {medicalFile.internal_reference_local}
          </Descriptions.Item>
        )}
        {medicalFile.internal_reference_hub && (
          <Descriptions.Item label={t('FRONT_MEDICAL_FILE_HUB_REFERENCE')} span={3}>
            {medicalFile.internal_reference_hub}
          </Descriptions.Item>
        )}
        {hasPermission('view medicalfiles pathology') &&
          Number.isInteger(medicalFile.pathology) && (
            <Descriptions.Item label={t('FRONT_MEDICAL_FILE_PATHOLOGY')} span={3}>
              {pathologies.find((p) => p.id === medicalFile.pathology)?.name}
            </Descriptions.Item>
          )}
        {medicalFile.interview && (
          <Descriptions.Item label={t('FRONT_MEDICAL_FILE_INTERVIEW')} span={3}>
            {`${t('FRONT_COMMON_FROM')} ${new Date(medicalFile.interview).toLocaleString()} ${t(
              'FRONT_COMMON_TO'
            )} ${interviewEnd.toLocaleString()}`}
          </Descriptions.Item>
        )}
        <Descriptions.Item label={t('FRONT_MEDICAL_FILE_STATUS')} span={3}>
          {medicalFile.step.description}
        </Descriptions.Item>
        {lc && (
          <Descriptions.Item label={t('FRONT_ROLE_LOCAL_COORDINATOR')} span={3}>
            {lc.interlocutor.name}
          </Descriptions.Item>
        )}
        {ld && (
          <Descriptions.Item label={t('FRONT_ROLE_LOCAL_DOCTOR')} span={3}>
            {ld.interlocutor.name}
          </Descriptions.Item>
        )}

        {hc && (
          <Descriptions.Item label={t('FRONT_ROLE_HUB_COORDINATOR')} span={3}>
            {hc.interlocutor.name}
          </Descriptions.Item>
        )}
        {hd && (
          <Descriptions.Item label={t('FRONT_ROLE_HUB_DOCTOR')} span={3}>
            {hd.interlocutor.name}
          </Descriptions.Item>
        )}

        {ec && (
          <Descriptions.Item label={t('FRONT_ROLE_EXPERT_COORDINATOR')} span={3}>
            {ec.interlocutor.name}
          </Descriptions.Item>
        )}
        {ed && (
          <Descriptions.Item label={t('FRONT_ROLE_EXPERT_DOCTOR')} span={3}>
            {ed.interlocutor.name}
          </Descriptions.Item>
        )}
      </Descriptions>
      <Flex style={{ justifyContent: 'space-between', marginTop: 20 }}>
        <span>
          {hasPermission('cancel medicalfiles') && step.reference !== 'any.stepabandonedfile' && (
            <Button
              type="danger"
              shape="round"
              icon={<DeleteOutlined />}
              onClick={() => dispatch(cancelMedicalFile(reference))}
            />
          )}
        </span>
        <span>
          {(hasPermission('update medicalfiles') || isExpertCoordinator(roles)) && (
            <Button
              type="primary"
              shape="round"
              icon={<EditOutlined />}
              onClick={() => history.push(`/medicals/${reference}/edit`)}
            />
          )}
        </span>
      </Flex>
    </>
  );
}

export default MedicalFileDetails;
