import moment from 'moment';
import i18n from '../../services/i18n';
import mobileCodes from '../../services/mobileCodes';
import request, { url } from '../../services/request';
import { fetchMetadata } from '../metadata/actions';
import { fetchPermissions } from '../permissions/actions';

export const SET_USER = 'SET_USER';
export const SET_PROFILE = 'SET_PROFILE';
export const SET_ERROR = 'SET_ERROR';
export const USER_IS_FETCHING = 'USER_IS_FETCHING';

export const userIsFetching = () => ({ type: USER_IS_FETCHING });
const setUser = (user) => ({ type: SET_USER, user });
export const setProfile = (profile) => ({ type: SET_PROFILE, profile });

// PROFILE
export const fetchProfile = async (userId) =>
  request(`/users/${userId}/profile`).then((p) => {
    if (p.phone) {
      const mcr = mobileCodes.find((mc) => mc.dial_code === p.phone.slice(0, -9));
      if (mcr) p.phone_country = mcr.code;
      p.phone = p.phone.slice(-9);
    }
    p.birthday = moment(moment.utc(p.birthday).startOf('day'));
    return p;
  });

export const fetchUser = () => async (dispatch) => {
  try {
    const { data } = await request('/me', 'GET');
    await dispatch(fetchPermissions(data.id));
    await dispatch(fetchMetadata());
    dispatch(setUser(data));
    await i18n.changeLanguage(data.language);
    try {
      const profile = await fetchProfile(data.id);
      dispatch(setProfile(profile));
    } catch (err) {
      dispatch(setProfile(null));
    }
  } catch (err) {
    dispatch(setUser(null));
  }
};

export const signIn = (credentials, history) => async (dispatch) =>
  request('/login', 'POST', credentials).then(async () => {
    await dispatch(fetchUser());
    return history.replace('/medicals');
  });

export const signOut = () => async () => {
  window.location.assign(`${url}/logout`);
};

export const connectAs = (credentials, history) => async (dispatch) => {
  dispatch(userIsFetching());
  return request('/logout', 'POST')
    .then(() => request('/login', 'POST', credentials))
    .then(() => history.replace('/medicals'))
    .then(() => window.location.reload(false));
};
