/* eslint-disable react/no-array-index-key */
import { InboxOutlined } from '@ant-design/icons';
import { Button, Input, Form, message, Select, Checkbox, Collapse, Table, Space } from 'antd';
import Title from 'antd/lib/typography/Title';
import Dragger from 'antd/lib/upload/Dragger';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import DocumentsList from '../../components/documents/List';
import CompanyConsents from '../../components/form/CompanyConsents';
import FormErrors from '../../components/form/FormErrors';
import Notifications from '../../components/form/Notifications';
import { removeDocument } from '../../redux/documents/actions';
import { useCountries, useParentCompanies } from '../../redux/metadata/selectors';
import acceptFileType from '../../services/AcceptFileType';
import { createCompany, updateCompany } from '../../services/api';
import { url } from '../../services/request';

function EntityCreate() {
  const { t } = useTranslation();
  const formRef = useRef();
  const location = useLocation();
  const countries = useCountries();
  const parentCompanies = useParentCompanies();

  const [[isFetching, errors], setState] = useState([false, null]);

  const company = location.state?.company;

  const create = async (values) => {
    setState([true, null]);

    try {
      if (company) {
        await updateCompany(company.id, values);
        message.success(t('FRONT_NOTIFICATION_UPDATE_SUCCESS'));
      } else {
        await createCompany(values);
        message.success(t('FRONT_NOTIFICATION_UPDATE_FAILED'));
        if (formRef.current) formRef.current.resetFields();
      }

      setState([false, null]);
    } catch (err) {
      setState([false, err]);
    }
  };

  return (
    <div>
      <Title>{company ? company.name : t('FRONT_ENTITY_CREATE')}</Title>
      <Form
        ref={formRef}
        layout="vertical"
        name="profile"
        onFinish={create}
        initialValues={company}
        scrollToFirstError
      >
        <Form.Item
          label={t('FRONT_ENTITY_NAME')}
          name="name"
          rules={[{ required: true, message: t('FRONT_FORM_MESSAGE_REQUIRED') }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label={t('FRONT_ENTITY_EMAIL')}
          name="email"
          rules={[{ type: 'email', required: true, message: t('FRONT_FORM_MESSAGE_REQUIRED') }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label={t('FRONT_ENTITY_COUNTRY')}
          name="country"
          rules={[{ required: true, message: t('FRONT_FORM_MESSAGE_REQUIRED') }]}
        >
          <Select
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            showSearch
          >
            {countries.map((c) => (
              <Select.Option key={`country-${c.id}`} value={c.code}>
                {c.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          label={t('FRONT_ENTITY_PARENT')}
          name="parent_value"
          rules={[{ required: true, message: t('FRONT_FORM_MESSAGE_REQUIRED') }]}
        >
          <Select
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            showSearch
          >
            {parentCompanies.map((c, index) => (
              <Select.Option key={`parent-company-${index}`} value={index}>
                {c}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item label={t('FRONT_ENTITY_ROLE')}>
          <Form.Item name="local" valuePropName="checked" initialValue={false} noStyle>
            <Checkbox>{t('FRONT_ENTITY_ROLE_LOCAL')}</Checkbox>
          </Form.Item>

          <Form.Item name="hub" valuePropName="checked" initialValue={false} noStyle>
            <Checkbox>{t('FRONT_ENTITY_ROLE_HUB')}</Checkbox>
          </Form.Item>

          <Form.Item name="expertise" valuePropName="checked" initialValue={false} noStyle>
            <Checkbox>{t('FRONT_ENTITY_ROLE_EXPERT')}</Checkbox>
          </Form.Item>
        </Form.Item>

        <FormErrors err={errors} />

        <Form.Item>
          <Button type="primary" htmlType="submit" loading={isFetching}>
            {t('FRONT_FORM_SAVE')}
          </Button>
        </Form.Item>
      </Form>

      {company && <CompanyConsents company={company.id} />}

      {company && <Notifications company={company.id} />}
    </div>
  );
}

export default EntityCreate;
