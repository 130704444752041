import { InboxOutlined } from '@ant-design/icons';
import { Button, Form, message, Space } from 'antd';
import Dragger from 'antd/lib/upload/Dragger';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import FormErrors from '../components/form/FormErrors';
import SelectLanguages from '../components/form/SelectLanguages';
import TableCustom from '../components/Table';
import useHasPermission from '../hooks/useHasPermission';
import { fetchSMOTranslations } from '../services/api';
import languages from '../services/languages';
import request from '../services/request';

function TranslationSMO() {
  const { t } = useTranslation();
  const params = useParams();
  const history = useHistory();
  const [err, setErr] = useState(null);
  const [[state, isFetching], setState] = useState([[], true]);
  const hasPermission = useHasPermission();

  const reference = params?.reference;
  const index = params?.index;

  const fetchData = useCallback(
    (page) =>
      fetchSMOTranslations(reference, index, page)
        .then((s) => setState([s, false]))
        .catch(() => setState([[], false])),
    [index, reference]
  );

  const addTranslation = async (values) => {
    setErr(null);
    try {
      const file = values.file.fileList[0];
      const formData = new FormData();
      formData.append('file', file.originFileObj);
      await request(`/medicalfiles/${reference}/smo/${index}/translations`, 'POST', formData, true);
      await fetchData();
      message.success(t('FRONT_NOTIFICATION_UPLOAD_SUCCESS'));
    } catch (error) {
      message.error(t('FRONT_NOTIFICATION_UPLOAD_FAILED'));
      setErr(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const columns = [
    { title: t('FRONT_DOCUMENT_SIZE'), dataIndex: 'size' },
    {
      title: t('FRONT_COMMON_UPDATED_AT'),
      dataIndex: 'updated_at',
      render: (value) => new Date(value).toLocaleString(),
    },
    {
      key: 'action',
      render: (value) => (
        <Space>
          <Button
            type="primary"
            onClick={() => history.push('/viewer/v2', { url: `/translations/${value.index}` })}
          >
            {t('FRONT_DOCUMENT_SHOW')}
          </Button>
        </Space>
      ),
    },
  ];

  if (!index) return null;

  return (
    <>
      <Button style={{ marginBottom: 10 }} type="primary" onClick={() => history.goBack()}>
        {t('FRONT_COMMON_BACK')}
      </Button>
      {hasPermission('create translations') && (
        <Form layout="vertical" onFinish={addTranslation}>
          <Form.Item
            name="file"
            rules={[{ required: true, message: t('FRONT_FORM_MESSAGE_REQUIRED') }]}
          >
            <Dragger beforeUpload={() => false} multiple>
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">{t('FRONT_DOCUMENT_FILE_UPLOAD')}</p>
            </Dragger>
          </Form.Item>

          <FormErrors err={err} />

          <Form.Item>
            <Button type="primary" htmlType="submit">
              {t('FRONT_FORM_SAVE')}
            </Button>
          </Form.Item>
        </Form>
      )}

      <TableCustom state={state} columns={columns} isFetching={isFetching} fetchData={fetchData} />
    </>
  );
}

export default TranslationSMO;
