import { Button, Descriptions, Form, Input, message, Skeleton, Space } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import Error404 from '../components/Error404';
import FormErrors from '../components/form/FormErrors';
import SelectCivility from '../components/form/SelectCivility';
import SelectCountry from '../components/form/SelectCountry';
import Profile from '../components/Profile';
import { createProfile, fetchProfile, updateProfile } from '../services/api';
import request from '../services/request';

function UserProfile() {
  const { t } = useTranslation();
  const params = useParams();
  const history = useHistory();

  const [[profile, isFetching], setProfile] = useState([{}, true]);
  const [[isUpdating, error], setState] = useState([false, null]);

  useEffect(() => {
    if (params.userId)
      fetchProfile(params.userId)
        .then(async (p) => {
          const { data } = await request(`/users/${params.userId}`);
          data.countries = data.countries.map((m) => m.id || m);
          setProfile([{ ...data, ...p }, false]);
        })
        .catch(() => setProfile([{}, false]));
  }, [params.userId]);

  const update = async (values) => {
    setState([true, null]);
    try {
      await request(`/users/${params.userId}`, 'PUT', {
        notification_email: values.notification_email,
        gender: values.gender,
        countries: values.countries,
      });
      if (profile) await updateProfile(params.userId, values);
      else await createProfile(params.userId, values);
      setState([false, null]);
      message.success(t('FRONT_NOTIFICATION_UPDATE_SUCCESS'));
    } catch (err) {
      setState([false, err]);
      message.error(t('FRONT_NOTIFICATION_UPDATE_FAILED'));
    }
  };

  if (isFetching) return <Skeleton />;
  if (!params.userId) return <Error404 />;

  return (
    <Form layout="vertical" name="profile" onFinish={update} initialValues={profile}>
      <Form.Item label={t('FRONT_USER_EMAIL_FOR_NOTIFICATION')} name="notification_email">
        <Input />
      </Form.Item>

      <SelectCivility />

      <Profile />

      <Form.Item label={t('FRONT_USER_POLICY_NUMBER')} name={['policy', 'number']}>
        <Input />
      </Form.Item>
      <Form.Item label={t('FRONT_USER_POLICY_PRODUCTID')} name={['policy', 'product_id']}>
        <Input />
      </Form.Item>

      <Form.Item label={t('FRONT_USER_REGISTRATION_TYPE')} name={['registration', 'type']}>
        <Input />
      </Form.Item>
      <Form.Item label={t('FRONT_USER_REGISTRATION_VALUE')} name={['registration', 'value']}>
        <Input />
      </Form.Item>

      <SelectCountry
        required={false}
        name={['registration', 'country']}
        label={t('FRONT_USER_REGISTRATION_COUNTRY')}
      />

      {error && <FormErrors err={error} />}

      <Form.Item>
        <Space>
          <Button type="primary" onClick={() => history.goBack()}>
            {t('FRONT_COMMON_BACK')}
          </Button>
          <Button type="primary" htmlType="submit" loading={isUpdating}>
            {t('FRONT_FORM_SAVE')}
          </Button>
        </Space>
      </Form.Item>
    </Form>
  );
}

export default UserProfile;
