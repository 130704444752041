import { Steps } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import useStepActive from '../../hooks/useStepActive';
import { useRoles } from '../../redux/user/selectors';
import { isPatient } from '../../services/rights';

const { Step } = Steps;

function NextStep() {
  const { t } = useTranslation();
  const roles = useRoles();
  const { afterStep } = useStepActive();

  const getCurrent = () => {
    if (afterStep('patient.stepfillsatisfactionform')) return 5;
    if (afterStep('patient.viewsmo')) return 4;
    if (afterStep('localdoctor.stepvalidatemedicalfile')) return 3;
    if (afterStep('patient.stepuploadmedicalfiles')) return 2;
    if (afterStep('patient.stepfillmedicalform')) return 1;
    return 0;
  };

  return (
    <>
      {isPatient(roles) && (
        <Steps
          style={{ backgroundColor: 'white', padding: 20, marginBottom: 20 }}
          current={getCurrent()}
        >
          <Step title={t('FRONT_STEP_1_TITLE')} description={t('FRONT_STEP_1_DESCRIPTION')} />
          <Step title={t('FRONT_STEP_2_TITLE')} description={t('FRONT_STEP_2_DESCRIPTION')} />
          <Step title={t('FRONT_STEP_3_TITLE')} description={t('FRONT_STEP_3_DESCRIPTION')} />
          <Step title={t('FRONT_STEP_4_TITLE')} description={t('FRONT_STEP_4_DESCRIPTION')} />
          <Step title={t('FRONT_STEP_5_TITLE')} description={t('FRONT_STEP_5_DESCRIPTION')} />
        </Steps>
      )}
    </>
  );
}

export default NextStep;
