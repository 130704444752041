/* eslint-disable react/prop-types */
import { Radio, Form } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

function SelectCivility({ name, label }) {
  const { t } = useTranslation();

  return (
    <Form.Item
      label={t(label || 'FRONT_USER_CIVILITY')}
      name={name || 'gender'}
      rules={[{ required: true, message: t('FRONT_FORM_MESSAGE_REQUIRED') }]}
    >
      <Radio.Group>
        <Radio value={1}>{t('FRONT_USER_MRS')}</Radio>
        <Radio value={0}>{t('FRONT_USER_MR')}</Radio>
      </Radio.Group>
    </Form.Item>
  );
}

export default SelectCivility;
