import { EditOutlined } from '@ant-design/icons';
import { Button, Skeleton } from 'antd';
import React from 'react';
import { useHistory } from 'react-router-dom';
import Flex from '../../components/Flex';
import PatientInfos from '../../components/infos/PatientInfos';
import useHasPermission from '../../hooks/useHasPermission';
import { usePatient } from '../../redux/medicalFile/selectors';

function PatientProfile() {
  const history = useHistory();
  const [patient, isFetching] = usePatient();
  const hasPermission = useHasPermission();

  if (isFetching) return <Skeleton />;

  return (
    <>
      <PatientInfos patient={patient} />
      {hasPermission('update profile') && (
        <Flex style={{ justifyContent: 'flex-end' }}>
          <Button
            type="primary"
            shape="round"
            icon={<EditOutlined />}
            onClick={() => history.push(`/profile/${patient.id}/edit`)}
          />
        </Flex>
      )}
    </>
  );
}

export default PatientProfile;
