import { ContactsOutlined, EditOutlined, TeamOutlined } from '@ant-design/icons';
import { Button, Form, Input, Space, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import TableCustom from '../components/Table';
import useHasPermission from '../hooks/useHasPermission';
import { fetchUser } from '../redux/user/actions';
import { fetchSearchUser, loginAsPatient } from '../services/api';

const styles = { icon: { fontSize: 18 } };

function SearchUser() {
  const { t } = useTranslation();
  const location = useLocation();
  const hasPermission = useHasPermission();
  const dispatch = useDispatch();

  const [[state, isFetching], setState] = useState([null, false]);
  const search = location?.state;
  const [values, setValues] = useState(search || {});

  const fetchData = (page) => {
    setState([null, true]);
    fetchSearchUser(values, page)
      .then((s) => setState([s, false]))
      .catch(() => setState([null, false]));
  };

  useEffect(() => {
    if (search) {
      setState([null, true]);
      fetchSearchUser(search)
        .then((s) => setState([s, false]))
        .catch(() => setState([null, false]));
    }
  }, [search]);

  const columns = [
    { title: t('FRONT_USER_ENTITY'), dataIndex: ['company', 'name'] },
    {
      title: t('FRONT_USER_ROLE'),
      dataIndex: 'roles',
      render: (v) => v.map((m) => m.alias).join(', '),
    },
    { title: t('FRONT_USER_LAST_NAME'), dataIndex: 'lastname' },
    { title: t('FRONT_USER_FIRST_NAME'), dataIndex: 'firstname' },
    { title: t('FRONT_USER_EMAIL'), dataIndex: 'email' },
    {
      key: 'action',
      render: (_, item) => (
        <Space size="large">
          <Tooltip title={t('FRONT_PATIENT_TOOLTIP_MEDICALS')}>
            <Link to={`/users/${item.id}/medicals`}>
              <ContactsOutlined style={styles.icon} />
            </Link>
          </Tooltip>

          {hasPermission('update patients') && (
            <Tooltip title={t('FRONT_PATIENT_TOOLTIP_EDIT')}>
              <Link to={{ pathname: '/patients/add', state: { patient: item } }}>
                <EditOutlined style={styles.icon} />
              </Link>
            </Tooltip>
          )}
          {hasPermission('update profile') && (
            <Tooltip title={t('FRONT_PATIENT_TOOLTIP_PROFILE')}>
              <Link to={{ pathname: `/profile/${item.id}/edit` }}>
                <TeamOutlined style={styles.icon} />
              </Link>
            </Tooltip>
          )}
          {hasPermission('impersonate users') && (
            <Button
              type="primary"
              onClick={() => loginAsPatient(item.id).then(() => dispatch(fetchUser()))}
            >
              {t('FRONT_USER_TAKE_IDENTITY')}
            </Button>
          )}
        </Space>
      ),
    },
  ];

  return (
    <>
      <Form
        initialValues={search || {}}
        name="search_user"
        layout="vertical"
        onFinish={() => {
          fetchData();
        }}
        onValuesChange={(a, b) => setValues(b)}
        scrollToFirstError
      >
        <Form.Item label={t('FRONT_MEDICAL_FILE_REFERENCE')} name="reference">
          <Input />
        </Form.Item>

        <Form.Item label={t('FRONT_USER_LAST_NAME')} name="lastname">
          <Input />
        </Form.Item>

        <Form.Item label={t('FRONT_USER_FIRST_NAME')} name="firstname">
          <Input />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit" loading={isFetching}>
            {t('FRONT_FORM_SEARCH')}
          </Button>
        </Form.Item>
      </Form>

      {state && (
        <TableCustom
          state={state}
          isFetching={isFetching}
          columns={columns}
          fetchData={fetchData}
        />
      )}
    </>
  );
}

export default SearchUser;
