import { LockOutlined, MailOutlined } from '@ant-design/icons';
import { Button, Form, Input } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Flex from '../../components/Flex';
import FormErrors from '../../components/form/FormErrors';
import Loading from '../../components/layouts/Loading';
import { signIn } from '../../redux/user/actions';
import { url } from '../../services/request';

const styles = {
  form: { minWidth: 400 },
  flex: { minHeight: '100vh' },
  button: { width: '100%' },
  divider: { margin: '16px 0' },
};

function Login() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const [state, setState] = useState({ isFetching: false, err: null });

  window.location.href = `${url}/login`;

  return <Loading />;

  /*   return (
    <Flex style={styles.flex}>
      <Form
        style={styles.form}
        name="login"
        onFinish={(values) => {
          setState({ isFetching: true, err: null });
          dispatch(signIn(values, history)).catch((err) => setState({ isFetching: false, err }));
        }}
      >
        <Form.Item
          name="email"
          rules={[{ type: 'email' }, { required: true, message: t('FRONT_FORM_MESSAGE_REQUIRED') }]}
        >
          <Input size="large" prefix={<MailOutlined />} placeholder={t('FRONT_USER_EMAIL')} />
        </Form.Item>

        <Form.Item
          name="password"
          rules={[{ required: true, message: t('FRONT_FORM_MESSAGE_REQUIRED') }]}
        >
          <Input.Password
            size="large"
            prefix={<LockOutlined />}
            type="password"
            placeholder={t('FRONT_USER_PASSWORD')}
          />
        </Form.Item>

        <FormErrors err={state.err} />

        <Button style={styles.button} type="link" onClick={() => history.push('/password/forgot')}>
          {t('FRONT_LOGIN_FORGOT_PASSWORD')}
        </Button>
        <Button style={styles.button} type="primary" htmlType="submit" loading={state.isFetching}>
          {t('FRONT_LOGIN_LABEL')}
        </Button>
      </Form>
    </Flex>
  ); */
}

export default Login;
