import { ContactsOutlined, EditOutlined, EyeOutlined, TeamOutlined } from '@ant-design/icons';
import { Button, Space, Tooltip } from 'antd';
import Title from 'antd/lib/typography/Title';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import TableCustom from '../../components/Table';
import useHasPermission from '../../hooks/useHasPermission';
import { fetchUser } from '../../redux/user/actions';
import { useUser } from '../../redux/user/selectors';
import { fetchPatients, loginAsPatient } from '../../services/api';

const styles = { icon: { fontSize: 18, cursor: 'pointer' } };

function PatientsList() {
  const dispatch = useDispatch();
  const [[state, isFetching], setState] = useState([[], true]);
  const { t } = useTranslation();
  const hasPermission = useHasPermission();
  const [user] = useUser();

  const fetchData = useCallback(
    (page, order) => {
      fetchPatients(user.id, page, order)
        .then((s) => setState([s, false]))
        .catch(() => setState([[], false]));
    },
    [user.id]
  );

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const columns = [
    { title: t('FRONT_USER_ENTITY'), dataIndex: ['company', 'name'], sorter: true },
    { title: t('FRONT_USER_LAST_NAME'), dataIndex: 'lastname', sorter: true },
    { title: t('FRONT_USER_FIRST_NAME'), dataIndex: 'firstname', sorter: true },
    {
      title: t('FRONT_USER_COUNTRIES'),
      dataIndex: 'countries',
      render: (values) => values.map((v) => v.name).join(', '),
      sorter: true,
    },
    {
      key: 'action',
      render: (_, item) => (
        <Space size="large">
          <Tooltip title={t('FRONT_PATIENT_TOOLTIP_MEDICALS')}>
            <Link to={`/users/${item.id}/medicals`}>
              <ContactsOutlined style={styles.icon} />
            </Link>
          </Tooltip>

          {hasPermission('view patients') && (
            <Tooltip title={t('FRONT_PATIENT_TOOLTIP_CONTACT')}>
              <Link to={`/users/${item.id}/contacts`}>
                <EyeOutlined style={styles.icon} />
              </Link>
            </Tooltip>
          )}

          {hasPermission('update patients') && (
            <Tooltip title={t('FRONT_PATIENT_TOOLTIP_EDIT')}>
              <Link to={{ pathname: '/patients/add', state: { patient: item } }}>
                <EditOutlined style={styles.icon} />
              </Link>
            </Tooltip>
          )}
          {hasPermission('update profile') && (
            <Tooltip title={t('FRONT_PATIENT_TOOLTIP_PROFILE')}>
              <Link to={{ pathname: `/profile/${item.id}/edit` }}>
                <TeamOutlined style={styles.icon} />
              </Link>
            </Tooltip>
          )}
          {hasPermission('impersonate users') && (
            <Button
              type="primary"
              onClick={() => loginAsPatient(item.id).then(() => dispatch(fetchUser()))}
            >
              {t('FRONT_USER_TAKE_IDENTITY')}
            </Button>
          )}
        </Space>
      ),
    },
  ];

  return (
    <div>
      <Title>{t('FRONT_PATIENT_LABELS')}</Title>
      <TableCustom state={state} isFetching={isFetching} columns={columns} fetchData={fetchData} />
    </div>
  );
}

export default PatientsList;
