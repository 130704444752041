/* eslint-disable react/prop-types */
import React from 'react';

const styles = { width: 30, height: 30, borderRadius: 50 };

function Engagement({ value }) {
  if (value === 0) return <div style={{ ...styles, backgroundColor: 'green' }} />;
  if (value === 1) return <div style={{ ...styles, backgroundColor: 'orange' }} />;
  return <div style={{ ...styles, backgroundColor: 'red' }} />;
}

export default Engagement;
