/* eslint-disable react/prop-types */
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { Form, Input, InputNumber, Radio, Select } from 'antd';
import React from 'react';
import DateFnsUtils from '@date-io/date-fns';
import { useTranslation } from 'react-i18next';
import { isIntermediary } from '../../services/rights';
import useLocaleDate from '../../hooks/useLocaleDate';
import { useContactIdentifier, useContactTypesPatient } from '../../redux/metadata/selectors';

function ThirdParty({ users, user, required = false }) {
  const { t } = useTranslation();
  const localeDate = useLocaleDate();
  const contactIdentifier = useContactIdentifier();
  const contactTypes = useContactTypesPatient();

  return (
    <>
      <Form.Item
        label={t('FRONT_USER_CONTACT_TYPE')}
        name="contact_type"
        rules={[{ required, message: t('FRONT_FORM_MESSAGE_REQUIRED') }]}
        initialValue={user?.contact_type || 0}
      >
        <Select>
          {Object.entries(contactTypes).map((item, i) => (
            <Select.Option value={i}>{t(item)}</Select.Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item
        shouldUpdate={(prevValues, currentValues) =>
          prevValues.contact_type !== currentValues.contact_type
        }
        noStyle
      >
        {({ getFieldValue }) =>
          (getFieldValue('contact_type') === 1 || getFieldValue('contact_type') === 2) && (
            <>
              <Form.Item
                label={t('FRONT_USER_CONTACT_TYPE_CHILD_1')}
                name="contact_identifier"
                rules={[{ required: true, message: t('FRONT_FORM_MESSAGE_REQUIRED') }]}
                initialValue={user?.contact?.type || 1}
              >
                <Select>
                  {Object.keys(contactIdentifier[getFieldValue('contact_type')]).map((key) => (
                    <Select.Option value={Number(key)}>
                      {t(contactIdentifier[getFieldValue('contact_type')][key])}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                label={t('FRONT_USER_CONTACT_CIVILITY')}
                name="contact_gender"
                rules={[{ required: true, message: t('FRONT_FORM_MESSAGE_REQUIRED') }]}
                initialValue={user?.contact?.gender}
              >
                <Radio.Group>
                  <Radio value={1}>{t('FRONT_USER_MRS')}</Radio>
                  <Radio value={0}>{t('FRONT_USER_MR')}</Radio>
                </Radio.Group>
              </Form.Item>

              <Form.Item
                label={t('FRONT_USER_CONTACT_FIRST_NAME')}
                name="contact_firstname"
                rules={[{ required: true, message: t('FRONT_FORM_MESSAGE_REQUIRED') }]}
                initialValue={user?.contact?.firstname}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label={t('FRONT_USER_CONTACT_LAST_NAME')}
                name="contact_lastname"
                rules={[{ required: true, message: t('FRONT_FORM_MESSAGE_REQUIRED') }]}
                initialValue={user?.contact?.lastname}
              >
                <Input />
              </Form.Item>

              {/* <Form.Item
                label={t('FRONT_USER_CONTACT_WEIGHT')}
                name="contact_weight"
                rules={[{ required: true, message: t('FRONT_FORM_MESSAGE_REQUIRED') }]}
                initialValue={user?.contact?.weight}
              >
                <InputNumber />
              </Form.Item>
              <Form.Item
                label={t('FRONT_USER_CONTACT_HEIGHT')}
                name="contact_height"
                rules={[{ required: true, message: t('FRONT_FORM_MESSAGE_REQUIRED') }]}
                initialValue={user?.contact?.height}
              >
                <InputNumber />
              </Form.Item> */}

              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={localeDate}>
                <Form.Item
                  label={t('FRONT_USER_CONTACT_BIRTH_DATE')}
                  name="contact_birthday"
                  rules={[{ required: true, message: t('FRONT_FORM_MESSAGE_REQUIRED') }]}
                >
                  <KeyboardDatePicker margin="none" id="date-picker-dialog" format="dd/MM/yyyy" />
                </Form.Item>
              </MuiPickersUtilsProvider>
            </>
          )
        }
      </Form.Item>

      <Form.Item
        shouldUpdate={(prevValues, currentValues) =>
          prevValues.contact_type !== currentValues.contact_type
        }
        noStyle
      >
        {({ getFieldValue }) =>
          getFieldValue('contact_type') === 3 && (
            <Form.Item label={t('FRONT_ROLE_INTERMEDIARY')} name="intermediary">
              <Select
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                showSearch
              >
                {users
                  .filter((f) => isIntermediary(f.roles))
                  .map((m) => (
                    <Select.Option key={m.id} value={m.id}>
                      {`${m.firstname} ${m.lastname} (${m.email})`}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
          )
        }
      </Form.Item>
    </>
  );
}

export default ThirdParty;
