import { Form, Select } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useTimeZones } from '../../redux/metadata/selectors';

function SelectTimeZones() {
  const { t } = useTranslation();
  const timeZones = useTimeZones();

  return (
    <Form.Item
      label={t('FRONT_USER_TIMEZONE')}
      name="timezone"
      rules={[{ required: true, message: t('FRONT_FORM_MESSAGE_REQUIRED') }]}
    >
      <Select
        filterOption={(input, option) =>
          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        showSearch
      >
        {timeZones.map((tz) => (
          <Select.Option key={tz} value={tz}>
            {tz}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  );
}

export default SelectTimeZones;
