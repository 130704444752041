import { Button, Result } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

function Error404() {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <Result
      status="404"
      title="404"
      subTitle={t('FRONT_COMMON_ERROR_404')}
      extra={
        <Button type="primary" onClick={() => history.goBack()}>
          {t('FRONT_COMMON_BACK')}
        </Button>
      }
    />
  );
}

export default Error404;
