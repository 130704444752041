/* eslint-disable react/prop-types */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-param-reassign */
/* eslint-disable jsx-a11y/aria-role */
import DateFnsUtils from '@date-io/date-fns';
import { KeyboardDateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { Form, Input } from 'antd';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useHasPermission from '../../../hooks/useHasPermission';
import useLocaleDate from '../../../hooks/useLocaleDate';
import useStepActive from '../../../hooks/useStepActive';
import { useMedicalFile } from '../../../redux/medicalFile/selectors';
import Flex from '../../Flex';
import Interlocutor from '../../form/Interlocutor';
import InterlocutorCompany from '../../form/InterlocutorCompany';
import SelectPathology from '../../form/SelectPathology';

function HKSMOForm({ companies }) {
  const { t } = useTranslation();
  const hasPermission = useHasPermission();
  const [medicalFile] = useMedicalFile();
  const { afterStep } = useStepActive();
  const localeDate = useLocaleDate();
  const [interviewEnd, setInterviewEnd] = useState(new Date(medicalFile.interview));
  const handleInterviewChange = (val) => {
    // eslint-disable-next-line no-restricted-globals
    if (isNaN(val)) return;
    const _interviewEnd = new Date(val);
    _interviewEnd.setHours(_interviewEnd.getHours() + 2);
    setInterviewEnd(_interviewEnd);
  };

  useEffect(() => {
    handleInterviewChange(new Date(medicalFile.interview));
  }, [medicalFile]);

  return (
    <>
      {hasPermission('update medicalfiles internal_reference_local') &&
        afterStep('localcoordinator.stepassigntolocaldoctor') && (
          <Form.Item
            label={t('FRONT_MEDICAL_FILE_LOCAL_REFERENCE')}
            name="internal_reference_local"
          >
            <Input />
          </Form.Item>
        )}

      {hasPermission('update medicalfiles internal_reference_hub') &&
        afterStep('hubcoordinator.stepassigntohubdoctor') && (
          <Form.Item label={t('FRONT_MEDICAL_FILE_HUB_REFERENCE')} name="internal_reference_hub">
            <Input />
          </Form.Item>
        )}

      {hasPermission('update medicalfiles pathology') && <SelectPathology required={false} />}

      {hasPermission('update medicalfiles interview') &&
        afterStep('localcoordinator.stepassigntolocaldoctor') && (
          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={localeDate}>
            <p>{t('FRONT_MEDICAL_FILE_INTERVIEW')}</p>
            <Flex style={{ justifyContent: 'start', alignItems: 'center' }}>
              <p style={{ marginRight: 20 }}>{t('FRONT_COMMON_FROM')}</p>
              <Form.Item style={{ marginRight: 20 }} name="interview" shouldUpdate>
                <KeyboardDateTimePicker
                  margin="none"
                  id="time-picker"
                  format="dd/MM/yyyy HH:mm"
                  onChange={handleInterviewChange}
                />
              </Form.Item>
              <p style={{ marginRight: 20 }}> {t('FRONT_COMMON_TO')} </p>
              <KeyboardDateTimePicker
                disabled
                margin="none"
                id="time-picker"
                format="dd/MM/yyyy HH:mm"
                style={{ marginBottom: 20 }}
                value={interviewEnd}
              />
            </Flex>
          </MuiPickersUtilsProvider>
        )}

      {hasPermission('assign Role Local Coordinator') && (
        <InterlocutorCompany
          label={t('FRONT_ROLE_LOCAL_COORDINATOR')}
          companies={companies}
          roleName="Local Coordinator"
        />
      )}

      {hasPermission('assign Role Local Doctor') &&
        afterStep('localcoordinator.stepassigntolocaldoctor') && (
          <InterlocutorCompany
            companies={companies}
            label={t('FRONT_ROLE_LOCAL_DOCTOR')}
            roleName="Local Doctor"
          />
        )}

      {hasPermission('assign Role Hub Coordinator') &&
        afterStep('localcoordinator.stepassigntohubcoordinator') && (
          <InterlocutorCompany
            companies={companies}
            label={t('FRONT_ROLE_HUB_COORDINATOR')}
            roleName="Hub Coordinator"
          />
        )}

      {hasPermission('assign Role Hub Doctor') &&
        afterStep('hubcoordinator.stepassigntohubdoctor') && (
          <InterlocutorCompany
            companies={companies}
            label={t('FRONT_ROLE_HUB_DOCTOR')}
            roleName="Hub Doctor"
          />
        )}

      {hasPermission('assign Role Expert Coordinator') &&
        afterStep('hubcoordinator.stepassigntoexpertcoordinator') && (
          <InterlocutorCompany
            companies={companies}
            label={t('FRONT_ROLE_EXPERT_COORDINATOR')}
            roleName="Expert Coordinator"
          />
        )}

      {hasPermission('assign Role Expert Doctor') &&
        afterStep('expertcoordinator.stepassigntoexpertdoctor') && (
          <Interlocutor label={t('FRONT_ROLE_EXPERT_DOCTOR')} roleName="Expert Doctor" />
        )}

      {hasPermission('assign Role Expert Doctor') &&
        afterStep('expertcoordinator.stepassigntoexpertdoctor') && (
          <Interlocutor
            label={t('FRONT_ROLE_EXPERT_DOCTOR_VIEWER')}
            roleName="Expert Doctor"
            type="Viewer"
            multiple
          />
        )}
    </>
  );
}

export default HKSMOForm;
