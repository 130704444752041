/* eslint-disable react/prop-types */
import { Button, Form, Input, Select } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import Title from 'antd/lib/typography/Title';
import FormErrors from '../../components/form/FormErrors';
import AskDocumentsTable from '../../components/AskDocumentsTable';
import { useFileTypeMedical } from '../../redux/metadata/selectors';
import { createAskDocuments } from '../../redux/askDocuments/actions';
import Error404 from '../../components/Error404';
import Flex from '../../components/Flex';
import { fetchStep, nextStep } from '../../redux/step/actions';
import useStepActive from '../../hooks/useStepActive';

function AskDocuments({ medicalFile }) {
  const params = useParams();
  const history = useHistory();
  const { t } = useTranslation();
  const ref = useRef();
  const fileTypes = useFileTypeMedical();
  const dispatch = useDispatch();
  const { isStep } = useStepActive();
  const [[isCreating, errors], setCreate] = useState([false, null]);

  const { reference } = medicalFile;

  const createDemand = async (values) => {
    setCreate([true, null]);
    try {
      dispatch(createAskDocuments(reference, values));
      if (ref.current) ref.current.resetFields();
      setCreate([false, null]);
    } catch (err) {
      setCreate([false, err]);
    }
  };

  if (!reference) return <Error404 />;

  return (
    <>
      <Button type="primary" onClick={() => history.goBack()}>
        {t('FRONT_COMMON_BACK')}
      </Button>
      {isStep('localdoctor.stepaskmedicalfiles') && (
        <Flex>
          <Button size="large" type="primary" onClick={() => dispatch(nextStep(reference, true))}>
            {t('FRONT_MEDICAL_FILE_SEND_ASK_DOCUMENTS')}
          </Button>
        </Flex>
      )}
      <Title level={5} style={{ marginTop: 10 }}>
        {t('FRONT_MEDICAL_FILE_ASK_DOCUMENTS_LIST')}
      </Title>
      <Form ref={ref} layout="vertical" onFinish={createDemand}>
        <Form.Item
          name="file_type_id"
          label={t('FRONT_DOCUMENT_SELECT_TYPE')}
          rules={[{ required: true, message: t('FRONT_FORM_MESSAGE_REQUIRED') }]}
        >
          <Select placeholder="Select">
            {fileTypes.map((f) => (
              <Select.Option key={f.id} value={f.id}>
                {f.alias}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item label={t('FRONT_DOCUMENT_DESCRIPTION')} name="description">
          <Input />
        </Form.Item>

        <FormErrors err={errors} />

        <Form.Item>
          <Button type="primary" htmlType="submit" loading={isCreating}>
            {t('FRONT_FORM_SAVE')}
          </Button>
        </Form.Item>
      </Form>
      <AskDocumentsTable reference={reference} />
    </>
  );
}

export default AskDocuments;
