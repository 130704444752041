import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Skeleton } from 'antd';
import { useTranslation } from 'react-i18next';
import TableCustom from '../../components/Table';
import { fetchMedicalActivities } from '../../services/api';

function MedicalFileLogs({ medicalFile }) {
  const { t } = useTranslation();
  const [[state, isFetching], setState] = useState([null, true]);

  const fetchData = useCallback(
    (page) =>
      fetchMedicalActivities(medicalFile.reference, page)
        .then((s) => setState([s, false]))
        .catch(() => setState([null, false])),
    [medicalFile.reference]
  );

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const columns = [
    { title: t('FRONT_LOGS_NAME'), dataIndex: 'log_name' },
    { title: t('FRONT_LOGS_DESCRIPTION'), dataIndex: 'description' },
    { title: t('FRONT_LOGS_CAUSER_NAME'), dataIndex: ['causer', 'name'] },
    {
      title: t('FRONT_COMMON_CREATED_AT'),
      dataIndex: 'created_at',
      render: (v) => new Date(v).toLocaleString(),
    },
  ];

  if (isFetching) return <Skeleton />;

  return (
    <TableCustom state={state} isFetching={isFetching} columns={columns} fetchData={fetchData} />
  );
}

MedicalFileLogs.propTypes = {
  medicalFile: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default MedicalFileLogs;
