import { InboxOutlined } from '@ant-design/icons';
import { Button, Form, Input, Space } from 'antd';
import Dragger from 'antd/lib/upload/Dragger';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import Error404 from '../components/Error404';
import FormErrors from '../components/form/FormErrors';
import request from '../services/request';

function UpdateSMO() {
  const { t } = useTranslation();
  const history = useHistory();
  const params = useParams();
  const [[isUpdating, errors], setUpdate] = useState([false, null]);

  const reference = params?.reference;
  const smo = params?.smo;

  const update = async (values) => {
    setUpdate([true, null]);
    try {
      const file = values.file.fileList[0];
      const formData = new FormData();
      formData.append('file', file.originFileObj);
      formData.append('name', values.name);
      await request(`/medicalfiles/${reference}/smo/${smo}`, 'POST', formData, true);
      setUpdate([false, null]);
      history.push(`/medicals/${reference}/smo`);
    } catch (err) {
      setUpdate([false, err]);
    }
  };

  if (!reference || !smo) return <Error404 />;

  return (
    <Form layout="vertical" onFinish={update}>
      <Form.Item
        name="name"
        label={t('FRONT_DOCUMENT_NAME')}
        rules={[{ required: true, message: t('FRONT_FORM_MESSAGE_REQUIRED') }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        name="file"
        rules={[{ required: true, message: t('FRONT_FORM_MESSAGE_REQUIRED') }]}
      >
        <Dragger beforeUpload={() => false} multiple>
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">{t('FRONT_FORM_MESSAGE_REQUIRED')}</p>
        </Dragger>
      </Form.Item>

      <FormErrors err={errors} />

      <Form.Item>
        <Space>
          <Button type="primary" onClick={() => history.goBack()}>
            {t('FRONT_COMMON_BACK')}
          </Button>
          <Button type="primary" htmlType="submit" loading={isUpdating}>
            {t('FRONT_FORM_SAVE')}
          </Button>
        </Space>
      </Form.Item>
    </Form>
  );
}

export default UpdateSMO;
