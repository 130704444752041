import { Alert, Button, Form, message, Select, Typography } from 'antd';
import moment from 'moment';
import { ArrowLeftOutlined } from '@ant-design/icons';
import React, { useCallback, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link, useHistory, Redirect } from 'react-router-dom';
import { useRoles, useUser } from '../../redux/user/selectors';
import request from '../../services/request';
import FormErrors from '../../components/form/FormErrors';
import SelectPathology from '../../components/form/SelectPathology';
import MedicalFileTypeRadio from '../../components/form/SelectMedicalFileType';
import { fetchAllPatients, fetchAllUsers } from '../../services/api';
import useHasPermission from '../../hooks/useHasPermission';
import SwitchConsent from '../../components/form/SwitchConsent';
import { useMedicaleFileType } from '../../redux/metadata/selectors';
import { isPatient } from '../../services/rights';
import ThirdParty from '../../components/form/ThirdParty';

function MedicalFileCreate() {
  const { t } = useTranslation();
  const history = useHistory();
  const medicaleFileTypes = useMedicaleFileType();
  const [state, setState] = useState({ isFetching: false, err: null, type: null });
  const [patients, setPatients] = useState([]);
  const [[users], setUsers] = useState([[], true]);
  const [user] = useUser();
  const roles = useRoles();
  const [form] = Form.useForm();
  const hasPermission = useHasPermission();

  useEffect(() => {
    fetchAllUsers().then((s) => setUsers([s, false]));
  }, []);

  const fetchUsers = useCallback(() => {
    fetchAllPatients(user.id)
      .then((s) => setPatients(s))
      .catch(() => setPatients([]));
  }, [user]);

  useEffect(() => {
    if (!isPatient(roles)) fetchUsers();
  }, [fetchUsers, roles]);

  const create = async (values) => {
    try {
      setState({ isFetching: true });
      const { data } = await request('/medicalfiles', 'POST', {
        ...values,
        contact_birthday: moment(values.contact_birthday).format(moment.HTML5_FMT.DATE),
      });
      history.push(`/medicals/${data.reference}`);
    } catch (err) {
      setState({ isFetching: false, err, type: state.type });
    }
  };

  const handleChange = (val) => {
    form.setFieldsValue({ type: val });
    setState((s) => ({ ...s, type: val }));
  };

  const resetType = () => {
    setState((s) => ({ ...s, type: null }));
  };

  if (isPatient(roles) && !user.profile) {
    message.info(t('FRONT_NOTIFICATION_COMPLETE_PROFILE'));
    return <Redirect to="/profile" />;
  }
  return (
    <>
      <Typography.Title>
        {state.type !== null ? medicaleFileTypes[state.type] : t('FRONT_MEDICAL_FILE_TYPE_CHOICE')}
      </Typography.Title>
      <Form layout="vertical" onFinish={create}>
        <Form.Item name="type" shouldUpdate hidden={state.type !== null}>
          <MedicalFileTypeRadio onChange={handleChange} />
        </Form.Item>
        {state.type !== null && (
          <>
            <Button type="link" onClick={resetType} icon={<ArrowLeftOutlined />}>
              {t('FRONT_MEDICAL_FILES_CHANGE_TYPE')}
            </Button>
            {hasPermission('create medicalfiles as') && (
              <Form.Item
                label={t('FRONT_MEDICAL_FILE_SELECT_PATIENT')}
                name="user_id"
                rules={[{ required: state.type === 1, message: t('FRONT_FORM_MESSAGE_REQUIRED') }]}
              >
                <Select
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  showSearch
                >
                  {patients.map((m) => (
                    <Select.Option key={m.id} value={m.id}>
                      {`${m.firstname} ${m.lastname} (${m.email})`}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            )}
            <SelectPathology required={state.type === 0} />

            <ThirdParty users={users} required />

            {state.type === 0 && (
              <>
                <Alert
                  type="warning"
                  style={{ marginBottom: 20 }}
                  message={
                    <Trans i18nKey="FRONT_MEDICAL_FILE_CONSENT">
                      Please read carefully our <Link to="/policy">Private Policy</Link>
                      .
                      <br />
                      By clicking on the boxes below, I consent to the processing of my personal
                      data in accordance with the <Link to="/policy">Private Policy</Link> for the
                      purpose of Second Medical Opinion service.
                      <br />I consent in particular of the processing of health information about
                      myself, and the potential transfer of my personal data abroad, including to
                      France where the personal data is hosted.
                    </Trans>
                  }
                />

                <SwitchConsent
                  name="private_collect"
                  label={
                    <Trans i18nKey="FRONT_MEDICAL_FILE_PRIVATE_COLLECT">
                      I agree with the collect and processing of my Personal Data, and especially
                      health information about myself, as described in the
                      <Link to="/policy">Private Policy</Link>
                    </Trans>
                  }
                />

                <SwitchConsent
                  name="private_transfer"
                  label={
                    <Trans i18nKey="FRONT_MEDICAL_FILE_PRIVATE_TRANSFER">
                      I agree with the possible transfer of my Personal Data as described in the
                      <Link to="/policy">Private Policy</Link>.
                    </Trans>
                  }
                />

                <SwitchConsent
                  name="private_approve"
                  label={
                    <Trans i18nKey="FRONT_MEDICAL_FILE_TRANSFER_APPROVE">
                      I approve the
                      <Link to="/consent_smo">Second Medical Opinion consent form</Link>.
                    </Trans>
                  }
                />
              </>
            )}

            {state.err && <FormErrors err={state.err} />}

            <Form.Item>
              <Button type="primary" htmlType="submit" loading={state.isFetching}>
                {t('FRONT_FORM_SAVE')}
              </Button>
            </Form.Item>
          </>
        )}
      </Form>
    </>
  );
}

export default MedicalFileCreate;
