import {
  FilePdfOutlined,
  FileProtectOutlined,
  MailOutlined,
  OrderedListOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { Tabs } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import useHasPermission from '../../../hooks/useHasPermission';
import useStepActive from '../../../hooks/useStepActive';
import { useMedicalFile } from '../../../redux/medicalFile/selectors';
import { useRoles, useUser } from '../../../redux/user/selectors';
import {
  isExpertDoctor,
  isHubDoctor,
  isLocalCoordinator,
  isLocalDoctor,
  isPatient,
} from '../../../services/rights';

const { TabPane } = Tabs;

function SMOTabs() {
  const { t } = useTranslation();

  const history = useHistory();
  const params = useParams();
  const location = useLocation();
  const roles = useRoles();
  const [user] = useUser();
  const [medicalFile] = useMedicalFile();
  const { isStep, afterStep } = useStepActive();
  const hasPermission = useHasPermission();

  return (
    <Tabs activeKey={location.pathname} onChange={(key) => history.push(key)}>
      <TabPane
        tab={
          <>
            <UserOutlined />
            {medicalFile.contact
              ? t('FRONT_MEDICAL_FILE_TABS_CONTACT')
              : t('FRONT_MEDICAL_FILE_TABS_PATIENT')}
          </>
        }
        key={`/medicals/${params.reference}/patient`}
      />
      {medicalFile.contact && (
        <TabPane
          tab={
            <>
              <UserOutlined />
              {t('FRONT_MEDICAL_FILE_TABS_PATIENT')}
            </>
          }
          key={`/medicals/${params.reference}/contact`}
        />
      )}
      {(isPatient(roles) ||
        isLocalCoordinator(roles) ||
        user.id === medicalFile.user?.contact?.id) && (
        <TabPane
          tab={
            <>
              <FilePdfOutlined />
              {t('FRONT_MEDICAL_FILE_TABS_ADMIN_DOCUMENTS')}
            </>
          }
          key={`/medicals/${params.reference}/documents_admin`}
        />
      )}
      <TabPane
        tab={
          <>
            <FileProtectOutlined />
            {t('FRONT_MEDICAL_FILE_TABS_MEDICAL_FILE')}
          </>
        }
        key={`/medicals/${params.reference}`}
      />
      <TabPane
        tab={
          <>
            <OrderedListOutlined />
            {t('FRONT_MEDICAL_FILE_TABS_EVENTS')}
          </>
        }
        key={`/medicals/${params.reference}/events`}
      />
      {hasPermission('view healthquestions') && (
        <TabPane
          tab={
            <>
              <FilePdfOutlined />
              {t('FRONT_MEDICAL_FILE_TABS_HEALTH_FORM')}
            </>
          }
          key={`/medicals/${params.reference}/health`}
        />
      )}
      {hasPermission('view health files') && (
        <TabPane
          tab={
            <>
              <FilePdfOutlined />
              {t('FRONT_MEDICAL_FILE_TABS_MEDICAL_DOCUMENTS')}
            </>
          }
          key={`/medicals/${params.reference}/documents`}
        />
      )}
      <TabPane
        tab={
          <>
            <FilePdfOutlined />
            {t('FRONT_MEDICAL_FILE_TABS_MEDICAL_CONSENTS')}
          </>
        }
        key={`/medicals/${params.reference}/consents`}
      />
      {(isPatient(roles) || isLocalDoctor(roles)) && (
        <TabPane
          tab={
            <>
              <MailOutlined />
              {t('FRONT_MEDICAL_FILE_TABS_COMMENTS_PATIENT')}
            </>
          }
          key={`/medicals/${params.reference}/comments/patient`}
        />
      )}
      {(isHubDoctor(roles) || isLocalDoctor(roles)) && (
        <TabPane
          tab={
            <>
              <MailOutlined />
              {t('FRONT_MEDICAL_FILE_TABS_COMMENTS_DOCTORS')}
            </>
          }
          key={`/medicals/${params.reference}/comments/doctors`}
        />
      )}
      {(isExpertDoctor(roles) || isHubDoctor(roles)) && (
        <TabPane
          tab={
            <>
              <MailOutlined />
              {t('FRONT_MEDICAL_FILE_TABS_MESSAGES_DOCTORS')}
            </>
          }
          key={`/medicals/${params.reference}/messages/doctors`}
        />
      )}
      {(isExpertDoctor(roles) ||
        ((isHubDoctor(roles) || isLocalDoctor(roles)) &&
          afterStep('hubdoctor.stepvalidatesmo'))) && (
        <TabPane
          tab={
            <>
              <MailOutlined />
              {t('FRONT_MEDICAL_FILE_TABS_SMO')}
            </>
          }
          key={`/medicals/${params.reference}/smo`}
        />
      )}
      {isPatient(roles) && afterStep('patient.viewsmo') && (
        <TabPane
          tab={
            <>
              <MailOutlined />
              {t('FRONT_MEDICAL_FILE_TABS_SMO')}
            </>
          }
          key={`/medicals/${params.reference}/smo/final`}
        />
      )}
      {isStep('any.fileclosed') &&
        (isPatient(roles) || isLocalCoordinator(roles) || isHubDoctor(roles)) && (
          <TabPane
            tab={
              <>
                <MailOutlined />
                {t('FRONT_MEDICAL_FILE_TABS_SATISFACTIONS')}
              </>
            }
            key={`/medicals/${params.reference}/satisfactions`}
          />
        )}
    </Tabs>
  );
}

export default SMOTabs;
