/* eslint-disable no-underscore-dangle */
/* eslint-disable jsx-a11y/aria-role */
import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button, Form, message, Skeleton } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import Flex from '../../components/Flex';
import FormErrors from '../../components/form/FormErrors';
import HKSMOForm from '../../components/workflow/HKSMO/HKSMO.form';
import SMOForm from '../../components/workflow/SMO/SMO.form';
import { fetchMedicalFileData } from '../../redux/medicalFile/actions';
import { useMedicalFile } from '../../redux/medicalFile/selectors';
import { fetchStep } from '../../redux/step/actions';
import { useStep } from '../../redux/step/selectors';
import { fetchAllCompanies } from '../../services/api';
import request from '../../services/request';

const styles = {
  wrapper: { flexDirection: 'row', alignItems: 'baseline' },
  switch: { marginLeft: 16 },
};

function MedicalFileEdit() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const [[companies, isFetching], setState] = useState([[], [], true]);
  const [[isUpdating, updateErr], setIsUpdating] = useState([false, null]);
  const [medicalFile] = useMedicalFile();

  const init = async () => {
    const c = await fetchAllCompanies().catch(() => []);

    setState([c, false]);
  };

  useEffect(() => {
    init();
  }, []);

  const update = async (values) => {
    setIsUpdating([true, null]);
    if (!values.interview) values.interview = undefined;
    try {
      await request(`/medicalfiles/${medicalFile.reference}`, 'PATCH', {
        ...values,
        interview: values.interview ? new Date(values.interview).toUTCString() : undefined,
      });
      dispatch(fetchStep(medicalFile.reference, true));
      dispatch(fetchMedicalFileData(medicalFile.reference));
      setIsUpdating([false, null]);
      history.push(`/medicals/${medicalFile.reference}`);
      message.success(t('FRONT_NOTIFICATION_UPLOAD_SUCCESS'));
    } catch (err) {
      message.error(t('FRONT_NOTIFICATION_UPLOAD_FAILED'));
      setIsUpdating([false, err]);
    }
  };

  if (isFetching) return <Skeleton />;

  return (
    <Form
      name="medical_file"
      layout="vertical"
      initialValues={medicalFile}
      onFinish={update}
      scrollToFirstError
    >
      {medicalFile.type === 0 && <SMOForm companies={companies} />}
      {medicalFile.type === 1 && <HKSMOForm companies={companies} />}
      <FormErrors err={updateErr} />
      <Flex style={{ justifyContent: 'space-between' }}>
        <Button type="primary" htmlType="submit" loading={isUpdating}>
          {t('FRONT_FORM_SAVE')}
        </Button>
        <Button
          type="danger"
          shape="round"
          icon={<ArrowLeftOutlined />}
          onClick={() => history.push(`/medicals/${medicalFile.reference}`)}
        />
      </Flex>
    </Form>
  );
}

export default MedicalFileEdit;
