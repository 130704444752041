import { Alert, Button, Form, message, Skeleton, Switch } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import FormErrors from '../../components/form/FormErrors';
import UserAvatar from '../../components/UserAvatar';
import Profile from '../../components/Profile';
import { useRoles, useUser } from '../../redux/user/selectors';
import { createProfile, fetchAllUsers, fetchProfile, updateProfile } from '../../services/api';
import WorkflowUser from '../../components/WorkflowUser';
import { fetchWorlflowUser } from '../../redux/workflowUser/actions';
import { isPatient } from '../../services/rights';
import AccountProfile from '../AccountProfile';
import SwitchConsent from '../../components/form/SwitchConsent';
import { setProfile } from '../../redux/user/actions';

function UserProfile() {
  const { t } = useTranslation();
  const [user, userIsLoading] = useUser();
  const dispatch = useDispatch();
  const roles = useRoles();
  const history = useHistory();

  const [[profile, users, profileIsFetching], setUserProfile] = useState([null, [], true]);
  const [[isFetching, error], setState] = useState([false, null]);

  const mount = useCallback(async () => {
    try {
      const [p, u] = await Promise.all([fetchProfile(user.id), fetchAllUsers().catch(() => null)]);
      setUserProfile([p, u, false]);
    } catch {
      setUserProfile([null, [], false]);
    }
  }, [user.id]);

  useEffect(() => {
    mount();
  }, [mount]);

  const update = async (values) => {
    setState([true, null]);
    let p = null;
    try {
      if (profile) {
        const { data } = await updateProfile(user.id, values);
        p = data;
      } else {
        const { data } = await createProfile(user.id, values);
        p = data;
      }
      dispatch(setProfile(p));

      await dispatch(fetchWorlflowUser());
      setState([false, null]);
      message.success(t('FRONT_NOTIFICATION_UPDATE_SUCCESS'));
      if (!profile) {
        history.push('/documents');
      }
    } catch (err) {
      setState([false, err]);
      message.error(t('FRONT_NOTIFICATION_UPDATE_FAILED'));
    }
  };

  if (!isPatient(roles)) return <AccountProfile />;
  if (profileIsFetching || userIsLoading) return <Skeleton />;

  return (
    <>
      <WorkflowUser />
      <UserAvatar user={user} />
      <Form
        layout="vertical"
        name="profile"
        onFinish={update}
        initialValues={{ country: user.countries && user.countries[0].code, ...profile }}
        scrollToFirstError
      >
        <Profile />

        <Alert
          type="warning"
          style={{ marginBottom: 20 }}
          message={
            <Trans i18nKey="FRONT_MEDICAL_FILE_CONSENT">
              Please read carefully our <Link to="/policy">Private Policy</Link>
              .
              <br />
              By clicking on the boxes below, I consent to the processing of my personal data in
              accordance with the <Link to="/policy">Private Policy</Link> for the purpose of Second
              Medical Opinion service.
              <br />I consent in particular of the processing of health information about myself,
              and the potential transfer of my personal data abroad, including to France where the
              personal data is hosted.
            </Trans>
          }
        />

        <SwitchConsent
          name="private_collect"
          label={
            <Trans i18nKey="FRONT_MEDICAL_FILE_PRIVATE_COLLECT">
              I agree with the collect and processing of my Personal Data, and especially health
              information about myself, as described in the <Link to="/policy">Private Policy</Link>
            </Trans>
          }
        />

        <SwitchConsent
          name="private_transfer"
          label={
            <Trans i18nKey="FRONT_MEDICAL_FILE_PRIVATE_TRANSFER">
              I agree with the possible transfer of my Personal Data as described in the
              <Link to="/policy">Private Policy</Link>.
            </Trans>
          }
        />

        {error && <FormErrors err={error} />}

        <Form.Item>
          <Button type="primary" htmlType="submit" loading={isFetching}>
            {t('FRONT_FORM_SAVE')}
          </Button>
        </Form.Item>
      </Form>
    </>
  );
}

export default UserProfile;
