/* eslint-disable react/prop-types */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Avatar, Comment, Form, Layout, Input, Button, Skeleton, message } from 'antd';
import { useTranslation } from 'react-i18next';
import { UserOutlined } from '@ant-design/icons';
import moment from 'moment';
import { fetchAll } from '../../services/api';
import request from '../../services/request';

const { Content } = Layout;
const { TextArea } = Input;

const styles = {
  content: { padding: '0 24px', minHeight: 280 },
  messages: { overflow: 'auto', maxHeight: 'calc(100vh - 500px)' },
};

function Comments({ url }) {
  const { t } = useTranslation();
  const commentsRef = useRef();
  const formRef = useRef();
  const [[messages, isFetching], setState] = useState([[], true]);

  const fetchMessages = useCallback(async () => {
    try {
      const result = await fetchAll(url);
      setState([result, false]);
    } catch (err) {
      setState([null, false]);
    }
  }, [url]);

  useEffect(() => {
    fetchMessages();
  }, [fetchMessages]);

  useEffect(() => {
    if (commentsRef.current) commentsRef.current.scrollTop = commentsRef.current.scrollHeight;
  }, [messages]);

  const send = async (values) => {
    try {
      const { data } = await request(url, 'POST', values);
      setState(([m, f]) => [[...m, data], f]);
      if (formRef.current) formRef.current.resetFields();
      return message.success(t('FRONT_NOTIFICATION_SEND_SUCCESS'));
    } catch (err) {
      return message.error(t('FRONT_NOTIFICATION_SEND_FAILED'));
    }
  };

  if (isFetching) return <Skeleton />;
  if (!messages) return null;

  return (
    <Content style={styles.content}>
      <div style={styles.messages} ref={commentsRef}>
        {messages.map((m) => (
          <Comment
            key={m.id}
            author={`${m.commenter.firstname} ${m.commenter.lastname}`}
            avatar={<Avatar icon={<UserOutlined />} />}
            content={m.comment}
            datetime={<span>{moment(m.updated_at).fromNow()}</span>}
          />
        ))}
      </div>

      <Comment
        avatar={<Avatar icon={<UserOutlined />} />}
        content={
          <Form ref={formRef} name="messages" onFinish={send}>
            <Form.Item
              name="comment"
              rules={[{ required: true, message: t('FRONT_FORM_MESSAGE_REQUIRED') }]}
            >
              <TextArea rows={4} />
            </Form.Item>
            <Form.Item>
              <Button htmlType="submit" type="primary">
                {t('FRONT_FORM_SEND')}
              </Button>
            </Form.Item>
          </Form>
        }
      />
    </Content>
  );
}

export default Comments;
