/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
import { Form, Select } from 'antd';
import React, { useMemo } from 'react';
import { useMedicalFile } from '../../redux/medicalFile/selectors';
import { useRoles } from '../../redux/metadata/selectors';
import request from '../../services/request';

function InterlocutorCompany({ companies, roleName, label }) {
  const roles = useRoles();
  const [medicalFile] = useMedicalFile();
  const { reference, interlocutors } = medicalFile;

  const filterCompanies = useMemo(
    () =>
      companies.filter((c) => {
        if (['Local Coordinator', 'Local Doctor'].includes(roleName) && c.local) return true;
        if (['Hub Coordinator', 'Hub Doctor'].includes(roleName) && c.hub) return true;
        if (['Expert Coordinator'].includes(roleName) && c.expertise) return true;
        return false;
      }),
    [companies, roleName]
  );

  const role = roles.find((r) => r.name === roleName);
  const defaultInterlocutor = interlocutors.find((f) => f.type === roleName);

  return (
    <Form.Item label={label}>
      <Select
        onSelect={async (value) => {
          await request(`/medicalfiles/${reference}/interlocutors`, 'POST', {
            company_id: value,
            type: role?.id,
          });
        }}
        filterOption={(input, option) =>
          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        defaultValue={defaultInterlocutor?.interlocutor.id}
        showSearch
      >
        {filterCompanies.map((c) => (
          <Select.Option key={c.id} value={c.id}>
            {c.name}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  );
}

export default InterlocutorCompany;
