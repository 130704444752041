import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import RouterAXA from './components/layouts/Router';
import LayoutPrivate from './components/layouts/LayoutPrivate';
import Login from './pages/authentication/Login';

function App() {
  return (
    <Router>
      <Switch>
        <Route path="/login" exact>
          <Login />
        </Route>
        <LayoutPrivate>
          <RouterAXA />
        </LayoutPrivate>
      </Switch>
    </Router>
  );
}

export default App;
