import { Form, Button, message, Skeleton } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import FormDynamic from '../form/FormDynamic';
import FormErrors from '../form/FormErrors';
import { fetchStep } from '../../redux/step/actions';
import { createHealthAnswers, fetchHealthAnswers, fetchHealthQuestions } from '../../services/api';
import { fetchMedicalFileData } from '../../redux/medicalFile/actions';
import useHasPermission from '../../hooks/useHasPermission';
import useStepActive from '../../hooks/useStepActive';

function HealthForm({ medicalFile }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const [[isSaving, saveErr], setIsSaving] = useState([false, null]);
  const [[answers, questions, isFetching], setState] = useState([[], [], true]);
  const hasPermission = useHasPermission();
  const { beforeStep } = useStepActive();

  const { reference } = medicalFile;

  const save = async (values) => {
    setIsSaving([true, null]);
    const arr = Object.entries(values)
      .filter(([, v]) => v)
      .map(([k, v]) => [k, v.toString()]);
    const a = arr.map(([key, answer]) => ({ health_question_id: Number(key), answer }));

    try {
      await createHealthAnswers(reference, {
        medical_file_id: medicalFile.id,
        answers: a,
      });
      dispatch(fetchStep(reference));
      dispatch(fetchMedicalFileData(reference));
      history.push(`/medicals/${medicalFile.reference}`);
      setIsSaving([false, null]);
      message.success(t('FRONT_NOTIFICATION_SAVE_SUCCESS'));
    } catch (err) {
      setIsSaving([false, err]);
      message.error(t('FRONT_NOTIFICATION_UPLOAD_FAILED'));
    }
  };

  const fetchAnswersQuestions = useCallback(async () => {
    try {
      const q = await fetchHealthQuestions(reference);
      const a = await fetchHealthAnswers(reference);
      setState([a, q, false]);
    } catch {
      setState([[], [], false]);
    }
  }, [reference]);

  useEffect(() => {
    fetchAnswersQuestions();
  }, [fetchAnswersQuestions]);

  if (isFetching) return <Skeleton />;

  const canEdit =
    hasPermission('update healthanswers') && beforeStep('localdoctor.stepvalidatemedicalfile');

  return (
    <Form layout="vertical" name="health" onFinish={save} scrollToFirstError>
      <FormDynamic questions={questions} responses={answers} disabled={!canEdit} />

      <FormErrors err={saveErr} />

      {canEdit && (
        <Form.Item>
          <Button type="primary" htmlType="submit" loading={isSaving}>
            {t('FRONT_FORM_SAVE')}
          </Button>
        </Form.Item>
      )}
    </Form>
  );
}

HealthForm.propTypes = {
  medicalFile: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default HealthForm;
