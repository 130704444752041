import { ContactsOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Button, Form, Input, message, Space } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import TableCustom from '../components/Table';
import useHasPermission from '../hooks/useHasPermission';
import { deleteUser, fetchSearchAllUsers } from '../services/api';

const styles = { icon: { fontSize: 18 } };

function SearchAllUsers() {
  const { t } = useTranslation();
  const location = useLocation();
  const hasPermission = useHasPermission();

  const [[state, isFetching], setState] = useState([null, false]);
  const search = location?.state;
  const [values, setValues] = useState(search || {});

  const fetchData = (page) => {
    setState([null, true]);
    fetchSearchAllUsers(values, page)
      .then((s) => setState([s, false]))
      .catch(() => setState([null, false]));
  };

  useEffect(() => {
    if (search) {
      setState([null, true]);
      fetchSearchAllUsers(search)
        .then((s) => setState([s, false]))
        .catch(() => setState([null, false]));
    }
  }, [search]);

  const removeCompany = async (id) => {
    try {
      setState(([c]) => [c, true]);
      await deleteUser(id);
      await fetchData(state.meta.current_page);
      message.success(t('FRONT_NOTIFICATION_DELETE_SUCCESS'));
    } catch (err) {
      setState(([c]) => [c, false]);
      message.error(t('FRONT_NOTIFICATION_DELETE_FAILED'));
    }
  };

  const columns = [
    { title: t('FRONT_USER_ENTITY'), dataIndex: ['company', 'name'] },
    {
      title: t('FRONT_USER_ROLE'),
      dataIndex: 'roles',
      render: (v) => v.map((m) => m.alias).join(', '),
    },
    { title: t('FRONT_USER_LAST_NAME'), dataIndex: 'lastname' },
    { title: t('FRONT_USER_FIRST_NAME'), dataIndex: 'firstname' },
    { title: t('FRONT_USER_EMAIL'), dataIndex: 'email' },
    {
      key: 'action',
      render: (_, item) => (
        <Space size="large">
          {hasPermission('update users') && (
            <Link to={{ pathname: '/users/add', state: { user: item } }}>
              <EditOutlined style={styles.icon} />
            </Link>
          )}
          {hasPermission('delete users') && (
            <DeleteOutlined
              onClick={() => removeCompany(item.id)}
              style={{ ...styles.icon, color: 'red' }}
            />
          )}
        </Space>
      ),
    },
  ];

  return (
    <>
      <Form
        initialValues={search || {}}
        name="search_all_users"
        layout="vertical"
        onFinish={() => {
          fetchData();
        }}
        onValuesChange={(a, b) => setValues(b)}
        scrollToFirstError
      >
        <Form.Item label={t('FRONT_USER_LAST_NAME')} name="lastname">
          <Input />
        </Form.Item>

        <Form.Item label={t('FRONT_USER_FIRST_NAME')} name="firstname">
          <Input />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit" loading={isFetching}>
            {t('FRONT_FORM_SEARCH')}
          </Button>
        </Form.Item>
      </Form>

      {state && (
        <TableCustom
          state={state}
          isFetching={isFetching}
          columns={columns}
          fetchData={fetchData}
        />
      )}
    </>
  );
}

export default SearchAllUsers;
