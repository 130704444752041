/* eslint-disable react/prop-types */
import { Form, Select } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useCountries } from '../../redux/metadata/selectors';

function SelectCountry({ name = 'country', required = true, label = null }) {
  const { t } = useTranslation();
  const countries = useCountries();

  return (
    <Form.Item
      label={label === null ? t('FRONT_USER_COUNTRY') : label}
      name={name}
      rules={[{ required, message: t('FRONT_FORM_MESSAGE_REQUIRED') }]}
    >
      <Select
        filterOption={(input, option) =>
          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        showSearch
      >
        {countries.map((c) => (
          <Select.Option key={c.code} value={c.code}>
            {c.name}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  );
}

export default SelectCountry;
