/* eslint-disable react/prop-types */
import { Form, Skeleton, Button, message } from 'antd';
import Title from 'antd/lib/typography/Title';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import FormDynamic from '../components/form/FormDynamic';
import FormErrors from '../components/form/FormErrors';
import useHasPermission from '../hooks/useHasPermission';
import useStepActive from '../hooks/useStepActive';
import {
  createSatisfactionAnswers,
  fetchSatisfaction,
  fetchSatisfactionAnswers,
} from '../services/api';

function MySatisfactionForm({ medicalFile }) {
  const { t } = useTranslation();
  const [state, setState] = useState({ questions: [], responses: [], isFetching: true });
  const [[isSaving, saveErr], setIsSaving] = useState([false, null]);
  const hasPermission = useHasPermission();
  const { beforeStep } = useStepActive();

  const reference = medicalFile?.reference;
  const id = medicalFile?.id;

  const fetchForm = useCallback(async () => {
    try {
      const questions = await fetchSatisfaction();
      const answers = await fetchSatisfactionAnswers(reference);
      setState({ questions, responses: answers, isFetching: false });
    } catch {
      setState({ questions: [], responses: [], isFetching: false });
    }
  }, [reference]);

  useEffect(() => {
    fetchForm();
  }, [fetchForm]);

  const save = async (values) => {
    setIsSaving([true, null]);
    const arr = Object.entries(values)
      .filter(([, v]) => v)
      .map(([k, v]) => [k, v.toString()]);
    const a = arr.map(([key, answer]) => ({ satisfaction_question_id: Number(key), answer }));

    try {
      await createSatisfactionAnswers(reference, {
        medical_file_id: id,
        answers: a,
      });
      setIsSaving([false, null]);
      message.success(t('FRONT_NOTIFICATION_SAVE_SUCCESS'));
    } catch (err) {
      setIsSaving([false, err]);
      message.error(t('FRONT_NOTIFICATION_SAVE_FAILED'));
    }
  };

  if (state.isFetching) return <Skeleton />;
  if (!id || !reference) return null;

  const canEdit = hasPermission('create satisfactionanswers') && state.responses.length === 0;

  return (
    <div>
      <Title>{t('FRONT_SATISFACTION_FORM_LABEL')}</Title>
      <Form layout="vertical" name="health" onFinish={save}>
        <FormDynamic
          questions={state.questions}
          responses={state.responses}
          isFetching={state.isFetching}
          satisfaction
          disabled={!canEdit}
        />

        <FormErrors err={saveErr} />

        <Form.Item>
          <Button type="primary" htmlType="submit" loading={isSaving}>
            {t('FRONT_FORM_SAVE')}
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}

export default MySatisfactionForm;
