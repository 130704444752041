const languages = [
  { label: 'FRONT_LANGUAGE_FR_LABEL', code: 'FRONT_LANGUAGE_FR_CODE', value: 'fr' },
  { label: 'FRONT_LANGUAGE_EN_LABEL', code: 'FRONT_LANGUAGE_EN_CODE', value: 'en' },
  { label: 'FRONT_LANGUAGE_ES_LABEL', code: 'FRONT_LANGUAGE_ES_CODE', value: 'es' },
  { label: 'FRONT_LANGUAGE_AR_LABEL', code: 'FRONT_LANGUAGE_AR_CODE', value: 'ar' },
  { label: 'FRONT_LANGUAGE_RU_LABEL', code: 'FRONT_LANGUAGE_RU_CODE', value: 'ru' },
  { label: 'FRONT_LANGUAGE_ZH_CN_LABEL', code: 'FRONT_LANGUAGE_ZH_CN_CODE', value: 'zh' },
];

export default languages;
