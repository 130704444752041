import Title from 'antd/lib/typography/Title';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import TableCustom from '../../components/Table';
import { useContactIdentifier, useContactTypes } from '../../redux/metadata/selectors';
import { fetchContacts } from '../../services/api';

function ContactsList() {
  const params = useParams();
  const userId = useMemo(() => params?.userId, [params]);
  const [[state, isFetching], setState] = useState([[], true]);
  const { t } = useTranslation();
  const contactTypes = useContactTypes();
  const contactIdentifier = useContactIdentifier();

  const fetchData = useCallback(
    (page, order) => {
      fetchContacts(userId, page, order)
        .then((s) => setState([s, false]))
        .catch(() => setState([[], false]));
    },
    [userId]
  );

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const columns = [
    {
      title: t('FRONT_USER_CONTACT_TYPE'),
      dataIndex: 'type',
      render: (value) => contactTypes[value],
      sorter: true,
    },
    {
      title: t('FRONT_USER_CONTACT_IDENTIFIER'),
      render: (_, item) =>
        item.identifier !== null ? contactIdentifier[item.type][item.identifier] : '',
      sorter: true,
    },
    { title: t('FRONT_USER_CONTACT_LAST_NAME'), dataIndex: 'lastname', sorter: true },
    { title: t('FRONT_USER_CONTACT_FIRST_NAME'), dataIndex: 'firstname', sorter: true },
  ];

  return (
    <div>
      <Title>{t('FRONT_CONTACTS_LABELS')}</Title>
      <TableCustom state={state} isFetching={isFetching} columns={columns} fetchData={fetchData} />
    </div>
  );
}

export default ContactsList;
