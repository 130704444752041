/* eslint-disable no-param-reassign */
import { Button, Input, Form, message } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import Title from 'antd/lib/typography/Title';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import FormErrors from '../../components/form/FormErrors';
import SelectCivility from '../../components/form/SelectCivility';
import SelectCountries from '../../components/form/SelectCountries';
import SelectLanguages from '../../components/form/SelectLanguages';
import SelectTimeZones from '../../components/form/SelectTimeZones';
import { useCountries } from '../../redux/metadata/selectors';
import { useUser } from '../../redux/user/selectors';
import { createUser, fetchAllUsers, updateUser } from '../../services/api';

function PatientCreate() {
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const formRef = useRef();
  const [state, setState] = useState({ isFetching: false, err: null });
  const [[users], setUsers] = useState([[], true]);
  const countries = useCountries();
  const [user] = useUser();
  const [form] = useForm();

  const patient = location.state?.patient;
  if (patient?.countries) patient.countries = patient.countries.map((m) => m.id || m);
  if (patient?.local_coordinator)
    patient.local_coordinator = patient.local_coordinator.id || patient.local_coordinator;

  useEffect(() => {
    fetchAllUsers().then((s) => setUsers([s, false]));
  }, []);

  const create = async (values) => {
    setState({ isFetching: true, err: null });
    if (!Array.isArray(values.countries)) values.countries = [values.countries];

    try {
      if (patient) {
        await updateUser(patient.id, values);
        message.success(t('FRONT_NOTIFICATION_UPDATE_SUCCESS'));
      } else {
        await createUser({
          ...values,
          company_id: user.company_id,
          roles: [9],
        });
        message.success(t('FRONT_NOTIFICATION_SAVE_SUCCESS'));
        if (formRef.current) formRef.current.resetFields();
      }

      setState({ isFetching: false, err: null });
    } catch (err) {
      setState({ isFetching: false, err });
    }
  };

  return (
    <>
      <Title>{t('FRONT_PATIENT_CREATE')}</Title>
      <Form
        form={form}
        ref={formRef}
        layout="vertical"
        name="create-patient"
        onFinish={create}
        initialValues={
          patient || {
            countries: countries.find(
              (c) => c.code === i18n.language.split('-').pop().toUpperCase()
            )?.id,
          }
        }
        scrollToFirstError
      >
        <SelectCivility />

        <Form.Item
          label={t('FRONT_USER_FIRST_NAME')}
          name="firstname"
          rules={[{ required: true, message: t('FRONT_FORM_MESSAGE_REQUIRED') }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label={t('FRONT_USER_LAST_NAME')}
          name="lastname"
          rules={[{ required: true, message: t('FRONT_FORM_MESSAGE_REQUIRED') }]}
        >
          <Input />
        </Form.Item>

        <Form.Item label={t('FRONT_USER_EMAIL_FOR_LOGIN')} name="email">
          <Input />
        </Form.Item>

        <SelectLanguages />

        <SelectCountries multiple={false} />

        <SelectTimeZones />

        <FormErrors err={state.err} />

        <Form.Item>
          <Button type="primary" htmlType="submit" loading={state.isFetching}>
            {t('FRONT_FORM_SAVE')}
          </Button>
        </Form.Item>
      </Form>
    </>
  );
}

export default PatientCreate;
