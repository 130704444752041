/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable no-use-before-define */
/* eslint-disable react/prop-types */
import { DownloadOutlined, EyeOutlined } from '@ant-design/icons';
import { Button, Form, Input, Switch } from 'antd';
import FormItem from 'antd/lib/form/FormItem';
import Title from 'antd/lib/typography/Title';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import Engagement from '../../components/medicalFile/Engagement';
import TableCustom from '../../components/Table';
import WorkflowUser from '../../components/WorkflowUser';
import useHasPermission from '../../hooks/useHasPermission';
import { usePathology } from '../../redux/metadata/selectors';
import { useRoles } from '../../redux/user/selectors';
import { fetchMedicalFiles } from '../../services/api';
import { url } from '../../services/request';
import { isPatient } from '../../services/rights';

const styles = { icon: { fontSize: 18 } };

function MedicalFilesList() {
  const history = useHistory();
  const { t } = useTranslation();
  const pathologies = usePathology();
  const [[state, isFetching], setState] = useState([null, true]);
  const hasPermission = useHasPermission();
  const roles = useRoles();

  const [all, setAll] = useState(false);

  const fetchData = async (page, order) => {
    setState([state, true]);
    fetchMedicalFiles(page, order, all)
      .then((s) => setState([s, false]))
      .catch(() => setState([null, false]));
  };

  useEffect(() => {
    fetchData();
  }, [all]);

  const columns = [
    {
      title: t('FRONT_MEDICAL_FILE_ENGAGEMENT'),
      dataIndex: ['step', 'commitment'],
      render: (value) => Engagement({ value }),
    },
    {
      title: t('FRONT_MEDICAL_FILE_REFERENCE'),
      dataIndex: 'reference',
      render: (value) => <Link to={`/medicals/${value}`}>{value}</Link>,
      sorter: true,
    },
    hasPermission('view medicalfiles pathology')
      ? {
          title: t('FRONT_MEDICAL_FILE_PATHOLOGY'),
          dataIndex: 'pathology',
          render: (i) => pathologies?.find((p) => p.id === i)?.name,
          sorter: true,
        }
      : {},
    {
      key: 'firstname',
      title: t('FRONT_USER_FIRST_NAME'),
      render: (_, record) => (
        <span>{record.contact ? record.contact.firstname : record.user.firstname}</span>
      ),
      sorter: true,
    },
    {
      key: 'lastname',
      title: t('FRONT_USER_LAST_NAME'),
      render: (_, record) => (
        <span>{record.contact ? record.contact.lastname : record.user.lastname}</span>
      ),
      sorter: true,
    },
    {
      title: t('FRONT_USER_COUNTRIES'),
      dataIndex: ['user', 'countries'],
      render: (values) => values.map((v) => v.name).join(', '),
    },
    { title: t('FRONT_MEDICAL_FILE_STATUS'), dataIndex: ['step', 'description'], sorter: true },
    !isPatient(roles)
      ? {
          title: t('FRONT_MEDICAL_FILE_ACTION_USER'),
          dataIndex: ['step', 'action_user'],
        }
      : {},
    {
      title: t('FRONT_COMMON_CREATED_AT'),
      dataIndex: 'updated_at',
      render: (r) => new Date(r).toLocaleString(),
      sorter: true,
    },
    {
      key: 'action',
      render: (_, record) => (
        <Link to={`/medicals/${record.reference}`}>
          <EyeOutlined style={styles.icon} />
        </Link>
      ),
    },
  ];

  return (
    <>
      <WorkflowUser />
      <Title>{t('FRONT_MEDICAL_FILE_LABELS')}</Title>

      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Form
          layout="inline"
          style={{ marginBottom: 10 }}
          onFinish={(v) => history.push('/medicals/search', v)}
        >
          <Form.Item label={t('FRONT_MEDICAL_FILE_REFERENCE')} name="reference">
            <Input />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              {t('FRONT_SIDEBAR_SEARCH')}
            </Button>
          </Form.Item>
        </Form>
        <div>
          <FormItem style={{ display: 'inline-block', marginRight: 15 }}>
            <Switch
              defaultChecked={false}
              checkedChildren={t('FRONT_MEDICAL_FILE_ALL')}
              unCheckedChildren={t('FRONT_MEDICAL_FILE_INPROGRESS')}
              onChange={(val) => setAll(val)}
            />
          </FormItem>
          {hasPermission('generate report') && (
            <Button type="primary" shape="round">
              <a href={`${url}/reporting`}>
                <DownloadOutlined style={{ fontSize: 20 }} />
              </a>
            </Button>
          )}
        </div>
      </div>

      <TableCustom state={state} columns={columns} isFetching={isFetching} fetchData={fetchData} />
    </>
  );
}

export default MedicalFilesList;
