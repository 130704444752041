import { CloseCircleOutlined, SettingOutlined, UserOutlined } from '@ant-design/icons';
import { Menu } from 'antd';
import Avatar from 'antd/lib/avatar/avatar';
import { Header } from 'antd/lib/layout/layout';
import SubMenu from 'antd/lib/menu/SubMenu';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Flex from '../Flex';
import { signOut } from '../../redux/user/actions';
import languages from '../../services/languages';
import { updateUser } from '../../services/api';

const styles = {
  header: { display: 'flex', justifyContent: 'space-between', padding: 0 },
  title: { color: 'white', fontSize: '1.3em', marginLeft: 10 },
  name: { marginLeft: 10 },
};

function HeaderAXA() {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { t, i18n } = useTranslation();
  const [user] = useSelector((state) => state.user);

  const updateLanguage = async (value) => {
    try {
      i18n.changeLanguage(value);
      await updateUser(user.id, { language: value });
      window.location.reload(false);
    } catch (err) {
      window.location.reload(false);
    }
  };

  return (
    <Header className="header" style={styles.header}>
      <div style={styles.title}>{t('FRONT_COMMON_SMO_TITLE')}</div>
      <Menu
        theme="dark"
        mode="horizontal"
        selectedKeys={[location.pathname, languages.find((l) => l.value === user.language)?.value]}
      >
        <Menu.Item key="/profile" onClick={() => history.push('/profile')}>
          <Flex>
            <Avatar icon={<UserOutlined />} />
            <div style={styles.name}>{`${user.firstname} ${user.lastname}`}</div>
          </Flex>
        </Menu.Item>
        <Menu.Item
          key="/account"
          icon={<SettingOutlined />}
          onClick={() => history.push('/account')}
        />
        <SubMenu title={t(languages.find((l) => l.value === user.language)?.code)}>
          {languages.map((l) => (
            <Menu.Item key={l.value} onClick={() => updateLanguage(l.value)}>
              {`${t(l.label)} (${t(l.code)})`}
            </Menu.Item>
          ))}
        </SubMenu>
        <Menu.Item icon={<CloseCircleOutlined />} onClick={() => dispatch(signOut())} />
      </Menu>
    </Header>
  );
}

export default HeaderAXA;
