import { Skeleton } from 'antd';
import Title from 'antd/lib/typography/Title';
import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import Error404 from '../../components/Error404';
import { HKSMORouter, HKSMOTabs, HKSMOWorkflow } from '../../components/workflow/HKSMO';
import { SMORouter, SMOTabs, SMOWorkflow } from '../../components/workflow/SMO';
import { fetchMedicalFileData } from '../../redux/medicalFile/actions';
import { useMedicalFile } from '../../redux/medicalFile/selectors';

function MedicalFile() {
  const { t: tr } = useTranslation();
  const params = useParams();
  const dispatch = useDispatch();
  const [medicalFile, isFetching] = useMedicalFile();

  useEffect(() => {
    dispatch(fetchMedicalFileData(params.reference));
  }, [params.reference, dispatch]);

  const [Workflow, Tabs, Router] = useMemo(() => {
    if (!medicalFile) return [];
    let w;
    let t;
    let r;
    switch (medicalFile.type) {
      case 0: // SMO
        w = SMOWorkflow;
        t = SMOTabs;
        r = SMORouter;
        break;
      case 1: // HKSMO
        w = HKSMOWorkflow;
        t = HKSMOTabs;
        r = HKSMORouter;
        break;
      default:
        w = SMOWorkflow;
        t = SMOTabs;
        r = SMORouter;
        break;
    }
    return [w, t, r];
  }, [medicalFile]);

  if (isFetching) return <Skeleton />;
  if (!medicalFile) return <Error404 />;

  return (
    <div>
      <Title>{`${tr('FRONT_MEDICAL_FILE_LABEL')} ${medicalFile.reference}`}</Title>
      <Workflow />
      <Tabs />
      <Router medicalFile={medicalFile} />
    </div>
  );
}

export default MedicalFile;
