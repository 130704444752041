import { EyeOutlined } from '@ant-design/icons';
import { Button, Form, Input } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import Engagement from '../components/medicalFile/Engagement';
import TableCustom from '../components/Table';
import useHasPermission from '../hooks/useHasPermission';
import { usePathology } from '../redux/metadata/selectors';
import { fetchSearchMedicalFiles, fetchSearchUser } from '../services/api';

const styles = { icon: { fontSize: 18 } };

function SearchMedicalFile() {
  const { t } = useTranslation();
  const location = useLocation();
  const [[state, isFetching], setState] = useState([null, false]);
  const search = location?.state;
  const [values, setValues] = useState(search || {});
  const hasPermission = useHasPermission();
  const pathology = usePathology();

  const fetchData = (page) => {
    setState([null, true]);
    fetchSearchMedicalFiles(values, page)
      .then((s) => setState([s, false]))
      .catch(() => setState([null, false]));
  };

  useEffect(() => {
    if (search) {
      setState([null, true]);
      fetchSearchMedicalFiles(search)
        .then((s) => setState([s, false]))
        .catch(() => setState([null, false]));
    }
  }, [search]);

  const columns = [
    {
      title: t('FRONT_MEDICAL_FILE_ENGAGEMENT'),
      dataIndex: ['step', 'commitment'],
      render: (value) => Engagement({ value }),
    },
    {
      title: t('FRONT_MEDICAL_FILE_REFERENCE'),
      dataIndex: 'reference',
      render: (value) => <Link to={`/medicals/${value}`}>{value}</Link>,
    },
    hasPermission('view medicalfiles pathology')
      ? {
          title: t('FRONT_MEDICAL_FILE_PATHOLOGY'),
          dataIndex: 'pathology',
          render: (i) => pathology[i],
        }
      : {},
    {
      key: 'firstname',
      title: t('FRONT_USER_FIRST_NAME'),
      render: (_, record) => (
        <span>{record.contact ? record.contact.firstname : record.user.firstname}</span>
      ),
      sorter: true,
    },
    {
      key: 'lastname',
      title: t('FRONT_USER_LAST_NAME'),
      render: (_, record) => (
        <span>{record.contact ? record.contact.lastname : record.user.lastname}</span>
      ),
      sorter: true,
    },
    {
      title: t('FRONT_USER_COUNTRIES'),
      dataIndex: ['user', 'countries'],
      render: (c) => c.map((v) => v.name).join(', '),
    },
    { title: t('FRONT_MEDICAL_FILE_STATUS'), dataIndex: ['step', 'description'] },
    {
      title: t('FRONT_MEDICAL_FILE_ACTION_USER'),
      dataIndex: 'step',
      render: (step) =>
        `${step?.action_user?.firstname || ''} ${step?.action_user?.lastname || ''}`,
    },
    {
      title: t('FRONT_COMMON_CREATED_AT'),
      dataIndex: 'updated_at',
      render: (r) => new Date(r).toLocaleString(),
    },
    {
      key: 'action',
      render: (_, record) => (
        <Link to={`/medicals/${record.reference}`}>
          <EyeOutlined style={styles.icon} />
        </Link>
      ),
    },
  ];

  return (
    <>
      <Form
        initialValues={search || {}}
        name="search_medical_file"
        layout="vertical"
        onFinish={() => {
          fetchData();
        }}
        onValuesChange={(a, b) => setValues(b)}
        scrollToFirstError
      >
        <Form.Item label={t('FRONT_MEDICAL_FILE_REFERENCE')} name="reference">
          <Input />
        </Form.Item>

        <Form.Item label={t('FRONT_USER_LAST_NAME')} name="lastname">
          <Input />
        </Form.Item>

        <Form.Item label={t('FRONT_USER_FIRST_NAME')} name="firstname">
          <Input />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit" loading={isFetching}>
            {t('FRONT_FORM_SEARCH')}
          </Button>
        </Form.Item>
      </Form>

      {state && (
        <TableCustom
          state={state}
          isFetching={isFetching}
          columns={columns}
          fetchData={fetchData}
        />
      )}
    </>
  );
}

export default SearchMedicalFile;
