/* eslint-disable react/prop-types */
import { UploadOutlined } from '@ant-design/icons';
import { Button, Form, Input, message, Radio, Skeleton, Upload } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import FormErrors from '../../components/form/FormErrors';
import { fetchMedicalFileData } from '../../redux/medicalFile/actions';
import { useMedicalFile } from '../../redux/medicalFile/selectors';
import { fetchStep } from '../../redux/step/actions';
import { createRatings } from '../../services/api';
import request from '../../services/request';

function MedicalFileRatings() {
  const { t } = useTranslation();
  const params = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const [medicalFile, isFetching] = useMedicalFile();
  const [[isCreating, errors], setState] = useState([false, null]);
  const [values, setValues] = useState(null);

  useEffect(() => {
    dispatch(fetchMedicalFileData(params.reference));
  }, [params.reference, dispatch]);

  const create = async (v) => {
    setState([true, null]);
    try {
      if (v.rewrite) {
        const file = v.file.fileList[0];
        const formData = new FormData();
        formData.append('file', file.originFileObj);
        formData.append('name', v.name);
        await request(`/medicalfiles/${medicalFile.reference}/smo`, 'POST', formData, true);
      }
      await createRatings(medicalFile.reference, v);
      await dispatch(fetchStep(medicalFile.reference, true));
      message.success(t('FRONT_NOTIFICATION_SAVE_SUCCESS'));
      history.push(`/medicals/${medicalFile.reference}`);
    } catch (err) {
      setState([false, err]);
      message.success(t('FRONT_NOTIFICATION_SAVE_FAILED'));
    }
  };

  if (isFetching) return <Skeleton />;
  if (!medicalFile) return null;

  return (
    <div>
      <Form
        layout="vertical"
        name="profile"
        onFinish={create}
        onValuesChange={(a, b) => setValues(b)}
      >
        <RadioChoices label={t('FRONT_MEDICAL_FILE_RATINGS_DIAGNOSTIC')} name="diagnostic" />

        <RadioChoices
          label={t('FRONT_MEDICAL_FILE_RATINGS_MEDICAL_PIECES')}
          name="medical_pieces"
        />

        <RadioChoices label={t('FRONT_MEDICAL_FILE_RATINGS_TREATMENT')} name="treatment" />

        <Form.Item
          label={t('FRONT_MEDICAL_FILE_RATINGS_QUALITY')}
          name="quality"
          rules={[{ required: true, message: t('FRONT_FORM_MESSAGE_REQUIRED') }]}
        >
          <Radio.Group>
            <Radio value={0}>{t('FRONT_MEDICAL_FILE_RATINGS_CLEAR_AND_COMPLETE')}</Radio>
            <Radio value={1}>{t('FRONT_MEDICAL_FILE_RATINGS_SKETCHY')}</Radio>
            <Radio value={2}>{t('FRONT_MEDICAL_FILE_RATINGS_UNCLEAR')}</Radio>
          </Radio.Group>
        </Form.Item>

        <Form.Item
          label={t('FRONT_MEDICAL_FILE_RATINGS_REWRITE')}
          name="rewrite"
          rules={[{ required: true, message: t('FRONT_FORM_MESSAGE_REQUIRED') }]}
        >
          <Radio.Group>
            <Radio value={1}>{t('FRONT_COMMON_TRUE')}</Radio>
            <Radio value={0}>{t('FRONT_COMMON_FALSE')}</Radio>
          </Radio.Group>
        </Form.Item>

        {values?.rewrite === 1 && (
          <>
            <Form.Item
              name="name"
              label={t('FRONT_DOCUMENT_NAME')}
              rules={[{ required: true, message: t('FRONT_FORM_MESSAGE_REQUIRED') }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="file"
              rules={[{ required: true, message: t('FRONT_FORM_MESSAGE_REQUIRED') }]}
            >
              <Upload maxCount={1} beforeUpload={() => false} multiple={false}>
                <Button icon={<UploadOutlined />}>{t('FRONT_MEDICAL_FILE_RATINGS_UPLOAD')}</Button>
              </Upload>
            </Form.Item>
          </>
        )}

        {errors && <FormErrors err={errors} />}

        <Form.Item>
          <Button type="primary" htmlType="submit" loading={isCreating}>
            {t('FRONT_FORM_SAVE')}
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}

function RadioChoices({ label, name }) {
  const { t } = useTranslation();

  return (
    <Form.Item
      label={label}
      name={name}
      rules={[{ required: true, message: t('FRONT_FORM_MESSAGE_REQUIRED') }]}
    >
      <Radio.Group>
        <Radio value={0}>{t('FRONT_MEDICAL_FILE_RATINGS_CONFIRMED')}</Radio>
        <Radio value={1}>{t('FRONT_MEDICAL_FILE_RATINGS_MODIFIED')}</Radio>
        <Radio value={2}>{t('FRONT_MEDICAL_FILE_RATINGS_NOT_APPLICABLE')}</Radio>
      </Radio.Group>
    </Form.Item>
  );
}

export default MedicalFileRatings;
