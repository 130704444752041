/* eslint-disable no-param-reassign */
import { Button, Form, Input, message, Skeleton } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import FormErrors from '../components/form/FormErrors';
import SelectCivility from '../components/form/SelectCivility';
import SelectCompanies from '../components/form/SelectCompanies';
import SelectCountries from '../components/form/SelectCountries';
import SelectLanguages from '../components/form/SelectLanguages';
import SelectRoles from '../components/form/SelectRoles';
import SelectTimeZones from '../components/form/SelectTimeZones';
import useHasPermission from '../hooks/useHasPermission';
import { useRolesCreate } from '../redux/metadata/selectors';
import { useUser } from '../redux/user/selectors';
import { updateUser } from '../services/api';

function AccountProfile() {
  const { t } = useTranslation();
  const [user, userIsFetching] = useUser();
  const hasPermission = useHasPermission();
  const rolesCreate = useRolesCreate();

  const [{ isFetching, error }, setState] = useState({ isFetching: false, error: null });

  const user2 = { ...user };
  user2.company_id = user.company?.id;
  user2.countries = user.countries.map((m) => m.id || m);
  user2.roles = user.roles.map((r) => r.id || r);

  const create = async (values) => {
    setState({ isFetching: true, err: null });
    try {
      if (!values.company_id) values.company_id = user.company.id;
      await updateUser(user.id, values);
      message.success(t('FRONT_NOTIFICATION_UPDATE_SUCCESS'));

      setState({ isFetching: false, err: null });
    } catch (err) {
      setState({ isFetching: false, err });
    }
  };

  if (userIsFetching) return <Skeleton />;

  return (
    <Form
      layout="vertical"
      name="account-profile"
      onFinish={create}
      initialValues={user2}
      scrollToFirstError
    >
      {rolesCreate.length > 0 && <SelectRoles condition />}

      {hasPermission('view companies') && <SelectCompanies />}

      <SelectCivility />

      <Form.Item label={t('FRONT_USER_EMAIL_FOR_NOTIFICATION')} name="notification_email">
        <Input />
      </Form.Item>

      <SelectLanguages />

      <SelectCountries />

      <SelectTimeZones />

      <FormErrors err={error} />

      <Form.Item>
        <Button type="primary" htmlType="submit" loading={isFetching}>
          {t('FRONT_FORM_SAVE')}
        </Button>
      </Form.Item>
    </Form>
  );
}

export default AccountProfile;
