import { Descriptions } from 'antd';
import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useMedicalFile } from '../../redux/medicalFile/selectors';
import { useContactIdentifier, useContactTypes } from '../../redux/metadata/selectors';

function ContactInfos() {
  const { t } = useTranslation();
  const [patient] = useMedicalFile();
  const { contact } = patient;
  const contactIdentifier = useContactIdentifier();
  const contactTypes = useContactTypes();
  if (!contact) return null;
  return (
    <Descriptions bordered>
      <Descriptions.Item label={t('FRONT_USER_CONTACT_TYPE')} span={3}>
        {contactTypes[contact.type]}
      </Descriptions.Item>
      {contact.identifier !== null && (
        <Descriptions.Item label={t('FRONT_USER_CONTACT_IDENTIFIER')} span={3}>
          {contactIdentifier[contact.type][contact.identifier]}
        </Descriptions.Item>
      )}
      <Descriptions.Item label={t('FRONT_USER_CONTACT_GENRE')} span={3}>
        {contact.gender === 1 ? t('FRONT_USER_MRS') : t('FRONT_USER_MR')}
      </Descriptions.Item>
      <Descriptions.Item label={t('FRONT_USER_CONTACT_FIRST_NAME')} span={3}>
        {contact.firstname}
      </Descriptions.Item>
      <Descriptions.Item label={t('FRONT_USER_CONTACT_LAST_NAME')} span={3}>
        {contact.lastname}
      </Descriptions.Item>
      <Descriptions.Item label={t('FRONT_USER_CONTACT_BIRTH_DATE')} span={3}>
        {contact.birthday ? moment.utc(contact.birthday).format('DD/MM/YYYY') : null}
      </Descriptions.Item>
    </Descriptions>
  );
}

export default ContactInfos;
