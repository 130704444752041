/* eslint-disable react/prop-types */
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Patient from '../../../pages/medicalFile/Patient';
import Comments from '../../medicalFile/Comments';
import HealthForm from '../../medicalFile/HealthForm';
import Consents from '../../../pages/medicalFile/Consents';
import MedicalFileDetails from '../../../pages/medicalFile/Details';
import MedicalFileDocuments from '../../../pages/medicalFile/Documents';
import MedicalFileDocumentsUser from '../../../pages/medicalFile/DocumentsUser';
import MedicalFileLogs from '../../../pages/medicalFile/Logs';
import SMO from '../../../pages/medicalFile/SMO/SMO';
import MedicalFileEdit from '../../../pages/medicalFile/Edit';
import Satisfaction from '../../../pages/medicalFile/Satisfaction';
import AskDocuments from '../../../pages/medicalFile/AskDocuments';
import ContactInfos from '../../infos/ContactInfos';

function HKSMORouter({ medicalFile }) {
  return (
    <Switch>
      <Route path="/medicals/:reference/patient" exact>
        <Patient />
      </Route>
      <Route path="/medicals/:reference/contact" exact>
        <ContactInfos />
      </Route>
      <Route path="/medicals/:reference/edit" exact>
        <MedicalFileEdit />
      </Route>
      <Route path="/medicals/:reference" exact>
        <MedicalFileDetails medicalFile={medicalFile} />
      </Route>
      <Route path="/medicals/:reference/smo" exact>
        <SMO reference={medicalFile.reference} />
      </Route>
      <Route path="/medicals/:reference/events" exact>
        <MedicalFileLogs medicalFile={medicalFile} />
      </Route>
      <Route path="/medicals/:reference/documents" exact>
        <MedicalFileDocuments medicalFile={medicalFile} />
      </Route>
      <Route path="/medicals/:reference/ask" exact>
        <AskDocuments medicalFile={medicalFile} />,
      </Route>
      <Route path="/medicals/:reference/consents" exact>
        <Consents medicalFile={medicalFile} />
      </Route>
      <Route path="/medicals/:reference/documents_admin" exact>
        <MedicalFileDocumentsUser medicalFile={medicalFile} />
      </Route>
      <Route path="/medicals/:reference/health" exact>
        <HealthForm medicalFile={medicalFile} />
      </Route>
      <Route path="/medicals/:reference/satisfactions" exact>
        <Satisfaction medicalFile={medicalFile} />
      </Route>
      <Route path="/medicals/:reference/comments/patient" exact>
        <Comments url={`/medicalfiles/${medicalFile.reference}/patientcomments`} />
      </Route>
      <Route path="/medicals/:reference/comments/doctors" exact>
        <Comments url={`/medicalfiles/${medicalFile.reference}/doctorcomments`} />
      </Route>
      <Route path="/medicals/:reference/messages/doctors" exact>
        <Comments url={`/medicalfiles/${medicalFile.reference}/comments`} />
      </Route>
      <Route>
        <MedicalFileDetails medicalFile={medicalFile} />
      </Route>
    </Switch>
  );
}

export default HKSMORouter;
