import request from '../../services/request';

export const SET_STEP = 'SET_STEP';
export const STEP_IS_FETCHING = 'STEP_IS_FETCHING';
export const STEP_STOP_FETCHING = 'STEP_STOP_FETCHING';

const setStep = (step) => ({ type: SET_STEP, step });
const isFetching = () => ({ type: STEP_IS_FETCHING });
const stopFetching = (err) => ({ type: STEP_STOP_FETCHING, err });

export const fetchStep =
  (reference, nextStep = false) =>
  async (dispatch) => {
    dispatch(isFetching());
    try {
      if (nextStep) await request(`/medicalfiles/${reference}/step/validate`, 'POST');
      const step = await request(`/medicalfiles/${reference}/step`, 'GET');
      dispatch(setStep(step));
    } catch (err) {
      dispatch(dispatch(stopFetching(err)));
    }
  };

export const nextStep = (reference) => async (dispatch) => {
  dispatch(isFetching());
  try {
    await request(`/medicalfiles/${reference}/step/validate`, 'POST');
    dispatch(fetchStep(reference));
  } catch (err) {
    dispatch(dispatch(stopFetching(err)));
  }
};
