import { fetchMedicalFile, fetchAllInterlocutors } from '../../services/api';
import request from '../../services/request';

export const SET_MEDICAL_FILE = 'SET_MEDICAL_FILE';
export const MEDICAL_FILE_IS_FETCHING = 'SET_PATIENT_IS_FETCHING';
export const MEDICAL_FILE_NOT_FOUND = 'SET_PATIENT_NOT_FOUND';

const setMedicalFile = (medicalFile) => ({ type: SET_MEDICAL_FILE, medicalFile });
const isFetching = () => ({ type: MEDICAL_FILE_IS_FETCHING });
const notFound = () => ({ type: MEDICAL_FILE_NOT_FOUND });

export const fetchMedicalFileData = (reference) => async (dispatch) => {
  dispatch(isFetching());
  try {
    const { data } = await fetchMedicalFile(reference);
    const interlocutors = await fetchAllInterlocutors(reference);
    return dispatch(setMedicalFile({ ...data, interlocutors }));
  } catch {
    return dispatch(notFound());
  }
};

export const cancelMedicalFile = (reference) => async (dispatch) => {
  await request(`/medicalfiles/${reference}/abandon`, 'POST');
  dispatch(fetchMedicalFileData(reference));
};
