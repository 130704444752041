/* eslint-disable react/prop-types */
import { CheckCircleOutlined, ReloadOutlined } from '@ant-design/icons';
import { Button, message } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useMedicalFile } from '../../redux/medicalFile/selectors';
import { nextStep } from '../../redux/step/actions';
import { useStep } from '../../redux/step/selectors';
import { useRoles, useUser } from '../../redux/user/selectors';
import request from '../../services/request';
import { hasRole } from '../../services/rights';
import Flex from '../Flex';
import NextStep from './NextStep';

export const ButtonValidate = ({ medicalFile, disabled, doubleCheck }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  return (
    <Button
      style={{ marginLeft: 15 }}
      size="large"
      className="medicalfiles__validatebutton"
      onClick={() => {
        if (doubleCheck) {
          const r = window.confirm(doubleCheck);
          if (r) dispatch(nextStep(medicalFile.reference));
        } else {
          dispatch(nextStep(medicalFile.reference));
        }
      }}
      icon={<CheckCircleOutlined />}
      disabled={disabled}
    >
      {t('FRONT_STEP_VALIDATE')}
    </Button>
  );
};

export const ButtonAction = ({ name, onClick }) => (
  <Button type="primary" size="large" onClick={onClick}>
    {name}
  </Button>
);

export const ButtonDescription = ({ description }) => <Button size="large">{description}</Button>;

function Workflow({ steps }) {
  const [currentStep, setCurrentStep] = useState(null);
  const [sendingNotif, setSendingNotif] = useState(false);
  const [medicalFile] = useMedicalFile();
  const [step] = useStep();
  const roles = useRoles();
  const [user] = useUser();

  useEffect(() => {
    if (step == null) return;
    const cs = steps.find((s) => s.reference === step.reference);
    setCurrentStep(cs);
  }, [steps, step]);

  const sendNotif = async () => {
    setSendingNotif(true);
    try {
      await request(`/medicalfiles/${medicalFile.reference}/resend`, 'POST');
    } catch (e) {
      message.error('Error');
    }
    setSendingNotif(false);
  };

  if (step == null) return null;
  const { roles: stepRoles, description, name, final, conditions_met: conditionsMet } = step;
  return (
    <>
      <NextStep />

      {currentStep && (
        <Flex style={{ marginBottom: 10 }}>
          {hasRole(roles, stepRoles) && !final ? (
            <>
              <ButtonAction
                name={name}
                onClick={currentStep.next}
                disabled={!conditionsMet && !currentStep.shouldValidate}
              />
              {currentStep.shouldValidate && (
                <ButtonValidate
                  medicalFile={medicalFile}
                  disabled={!conditionsMet}
                  doubleCheck={currentStep.doubleCheck}
                />
              )}
            </>
          ) : (
            <ButtonDescription description={description} />
          )}
          {hasRole(user.roles, [currentStep.reloader]) && (
            <Button
              style={{ marginLeft: 15 }}
              type="primary"
              shape="round"
              size="large"
              loading={sendingNotif}
              icon={<ReloadOutlined />}
              onClick={sendNotif}
            />
          )}
        </Flex>
      )}
    </>
  );
}

export default Workflow;
