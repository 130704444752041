/* eslint-disable react/prop-types */
import { DeleteOutlined } from '@ant-design/icons';
import { Button, Space, Table } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import Title from 'antd/lib/typography/Title';
import { useMedicalFile } from '../../redux/medicalFile/selectors';
import { useFileTypeMedical } from '../../redux/metadata/selectors';
import { groupBy } from '../../services/helper';
import UploadPostal from './UploadPostal';
import { url } from '../../services/request';
import acceptFileType from '../../services/AcceptFileType';

const styles = { icon: { fontSize: 18, color: 'red', cursor: 'pointer' } };

function DocumentsList({ documents, isFetching, onRemove, isAdmin }) {
  const { t } = useTranslation();
  const history = useHistory();
  const fileTypes = useFileTypeMedical();
  const [medicalFile] = useMedicalFile();

  const { reference } = medicalFile;
  const documentsUpload = groupBy(
    documents?.filter((d) => d.uploaded),
    'file_type_id'
  );
  const documentsPostal = documents?.filter((d) => !d.uploaded);

  const getTotal = (files) => {
    let total = 0;
    files.map((file) => {
      total += file.size;
      return file;
    });
    return total;
  };

  const columns = [
    {
      title: t('FRONT_DOCUMENT_TYPE'),
      dataIndex: 'file_type_id',
      render: (value) => fileTypes.find((f) => f.id === value)?.alias,
    },
    {
      title: t('FRONT_DOCUMENT_COUNT'),
      dataIndex: 'groupBy',
      render: (value) => value?.length.toString(),
    },
    {
      key: 'action',
      render: (value) => (
        <Space>
          <Button
            type="primary"
            onClick={async () => {
              history.push('/viewer', {
                length: getTotal(value.groupBy),
                url: null,
                reference,
                files: value.groupBy,
              });
            }}
            disabled={!value.groupBy.find((v) => acceptFileType(v))}
          >
            {t('FRONT_DOCUMENT_SHOW')}
          </Button>
          <Button type="primary">
            <a href={`${url}/medicalfiles/${reference}/files/zip/${value.file_type_id}`}>
              {t('FRONT_COMMON_DOWNLOAD')}
            </a>
          </Button>
        </Space>
      ),
    },
  ];

  const columnsChild = [
    !isAdmin ? { title: t('FRONT_DOCUMENT_NAME'), dataIndex: 'name' } : {},
    { title: t('FRONT_DOCUMENT_SIZE'), dataIndex: 'size', render: (v) => Math.floor(v / 1000) },
    {
      title: t('FRONT_COMMON_UPDATED_AT'),
      dataIndex: 'updated_at',
      render: (value) => new Date(value).toLocaleString(),
    },
    {
      key: 'action',
      render: (value) => (
        <Space>
          <Button
            type="primary"
            onClick={() => {
              history.push('/viewer', {
                length: value.size,
                url: null,
                reference,
                files: [value],
              });
            }}
            disabled={!acceptFileType(value)}
          >
            {t('FRONT_DOCUMENT_SHOW')}
          </Button>
          <Button type="primary">
            <a
              target="_blank"
              rel="noreferrer"
              href={`${url}/medicalfiles/${reference}/files/${value.index}`}
            >
              {t('FRONT_COMMON_DOWNLOAD')}
            </a>
          </Button>
          <Button
            type="primary"
            onClick={() => history.push('/translation', { index: value.index, reference })}
          >
            {`${t('FRONT_DOCUMENT_SHOW_TRADUCTIONS')} (${value.translations_count})`}
          </Button>
          <DeleteOutlined onClick={() => onRemove(value.index)} style={styles.icon} />
        </Space>
      ),
    },
  ];

  const columnsPostal = [
    { title: t('FRONT_DOCUMENT_NAME'), dataIndex: 'name' },
    { title: t('FRONT_DOCUMENT_POSTAL_TYPE'), dataIndex: 'postal_type' },
    { title: t('FRONT_DOCUMENT_POSTAL_NUMBER'), dataIndex: 'postal_number' },
    {
      key: 'action',
      dataIndex: 'index',
      render: (value) => <UploadPostal reference={reference} index={value} />,
    },
  ];

  return (
    <>
      <Table
        rowKey="id"
        dataSource={documentsUpload}
        columns={columns}
        loading={isFetching}
        expandable={{
          expandedRowRender: (record) => (
            <Table
              rowKey="id"
              columns={columnsChild}
              dataSource={record.groupBy}
              pagination={false}
            />
          ),
        }}
      />
      <>
        <Title level={5} style={{ marginTop: 10 }}>
          {t('FRONT_DOCUMENT_POSTAL')}
        </Title>
        <Table
          rowKey="id"
          dataSource={documentsPostal}
          columns={columnsPostal}
          loading={isFetching}
        />
      </>
    </>
  );
}

export default DocumentsList;
