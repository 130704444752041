import { Card, Col, Row } from 'antd';
import React from 'react';
import { Trans } from 'react-i18next';
import PropTypes from 'prop-types';
import Text from 'antd/lib/typography/Text';
import SMOImage from '../../assets/images/SMO.jpg';
import HKSMOImage from '../../assets/images/HKSMO.jpg';
import { useRoles, useUser } from '../../redux/user/selectors';
import { isPatient } from '../../services/rights';

function SelectMedicalFileType({ onChange }) {
  const [user] = useUser();
  const roles = useRoles();

  const handleClick = (val) => {
    onChange?.(val);
  };
  return (
    <Row gutter={[16, 16]} align="stretch">
      <Col sm={24} md={12}>
        <MedicalFileTypeCard
          title={<Trans i18nKey="FRONT_MEDICAL_FILES_SMO_TITLE" />}
          description={<Trans i18nKey="FRONT_MEDICAL_FILES_SMO_DESCRIPTION" />}
          backgroundImage={SMOImage}
          action={<Trans i18nKey="FRONT_MEDICAL_FILES_SMO_ACTION" />}
          onClick={() => handleClick(0)}
        />
      </Col>
      <Col sm={24} md={12}>
        {!isPatient(roles) && (
          <MedicalFileTypeCard
            title={<Trans i18nKey="FRONT_MEDICAL_FILES_HKSMO_TITLE" />}
            description={<Trans i18nKey="FRONT_MEDICAL_FILES_HKSMO_DESCRIPTION" />}
            backgroundImage={HKSMOImage}
            action={<Trans i18nKey="FRONT_MEDICAL_FILES_HKSMO_ACTION" />}
            onClick={() => handleClick(1)}
          />
        )}
      </Col>
    </Row>
  );
}

export default SelectMedicalFileType;

SelectMedicalFileType.propTypes = {
  onChange: PropTypes.func.isRequired,
};

function MedicalFileTypeCard({ title, description, backgroundImage, action, onClick }) {
  return (
    <Card
      hoverable
      key={Math.random()}
      className="card__medicalfiletype"
      cover={<img src={backgroundImage} alt={title} className="card__medicalfiletype__cover" />}
      bodyStyle={{ flexGrow: 1 }}
      onClick={onClick}
      actions={[
        <div
          type="link"
          className="card__medicalfiletype__action"
          style={{ fontSize: 16, lineHeight: 1 }}
        >
          <Text style={{ maxWidth: '100%' }} ellipsis>
            {action}
          </Text>
        </div>,
      ]}
    >
      <Card.Meta title={title} description={description} />
    </Card>
  );
}

MedicalFileTypeCard.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  backgroundImage: PropTypes.string.isRequired,
  action: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};
