/* eslint-disable no-undef */
import { EyeOutlined } from '@ant-design/icons';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, Link } from 'react-router-dom';
import Engagement from '../../components/medicalFile/Engagement';
import Error404 from '../../components/Error404';
import TableCustom from '../../components/Table';
import useHasPermission from '../../hooks/useHasPermission';
import { usePathology } from '../../redux/metadata/selectors';
import { fetchAllUserMedicalFiles } from '../../services/api';

const styles = { icon: { fontSize: 18 } };

function UserMedicalFiles() {
  const { t } = useTranslation();
  const params = useParams();
  const hasPermission = useHasPermission();
  const pathology = usePathology();
  const userId = useMemo(() => params?.userId, [params]);

  const [[state, isFetching], setState] = useState([null, true]);

  const fetchData = useCallback(
    (page) => {
      fetchAllUserMedicalFiles(userId, page)
        .then((s) => setState([s, false]))
        .catch(() => setState([null, false]));
    },
    [userId]
  );

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const columns = [
    {
      title: t('FRONT_MEDICAL_FILE_ENGAGEMENT'),
      dataIndex: ['step', 'commitment'],
      render: (value) => Engagement({ value }),
    },
    {
      title: t('FRONT_MEDICAL_FILE_REFERENCE'),
      dataIndex: 'reference',
      render: (value) => <Link to={`/medicals/${value}`}>{value}</Link>,
    },
    hasPermission('view medicalfiles pathology')
      ? {
          title: t('FRONT_MEDICAL_FILE_PATHOLOGY'),
          dataIndex: 'pathology',
          render: (i) => pathology[i],
        }
      : {},
    { title: t('FRONT_USER_FIRST_NAME'), dataIndex: ['user', 'firstname'] },
    { title: t('FRONT_USER_LAST_NAME'), dataIndex: ['user', 'lastname'] },
    {
      title: t('FRONT_USER_COUNTRIES'),
      dataIndex: ['user', 'countries'],
      render: (values) => values.map((v) => v.name).join(', '),
    },
    { title: t('FRONT_MEDICAL_FILE_STATUS'), dataIndex: ['step', 'description'] },
    {
      title: t('FRONT_MEDICAL_FILE_ACTION_USER'),
      dataIndex: 'step',
      render: (step) =>
        `${step?.action_user?.firstname || ''} ${step?.action_user?.lastname || ''}`,
    },
    {
      title: t('FRONT_COMMON_CREATED_AT'),
      dataIndex: 'updated_at',
      render: (r) => new Date(r).toLocaleString(),
    },
    {
      key: 'action',
      render: (_, record) => (
        <Link to={`/medicals/${record.reference}`}>
          <EyeOutlined style={styles.icon} />
        </Link>
      ),
    },
  ];

  if (!userId) return <Error404 />;

  return (
    <TableCustom state={state} columns={columns} isFetching={isFetching} fetchData={fetchData} />
  );
}

export default UserMedicalFiles;
