import { UserOutlined } from '@ant-design/icons';
import { Avatar, Upload } from 'antd';
import React from 'react';
import PropTypes from 'prop-types';
import Flex from './Flex';

const styles = {
  flex: { justifyContent: 'start', marginBottom: 20 },
  name: { fontSize: 20, fontWeight: 600, marginLeft: 10 },
  upload: { cursor: 'pointer' },
};

function UserAvatar({ user }) {
  return (
    <Flex style={styles.flex}>
      <Upload showUploadList={false}>
        {false ? (
          <img src="" alt="avatar" />
        ) : (
          <Avatar style={styles.upload} size={64} icon={<UserOutlined />} />
        )}
      </Upload>
      <div style={styles.name}>{`${user.firstname} ${user.lastname}`}</div>
    </Flex>
  );
}

UserAvatar.propTypes = {
  user: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default UserAvatar;
