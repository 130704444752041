import moment from 'moment';
import queryString from 'query-string';
import request, { url as baseurl } from './request';

export const fetchAll = async (base, filters = []) => {
  const url = new URL(baseurl + base);
  filters.map((f) => {
    url.searchParams.append(f.name, f.value);
    return f;
  });
  const first = await request(
    `${url.pathname}${Array.from(url.searchParams).length > 0 ? `?${url.searchParams}` : ''}`
  );
  const lastPage = first.meta?.last_page || first.last_page;
  if (lastPage > 1) {
    const promises = [];
    for (let i = 2; i <= lastPage; i += 1) {
      url.searchParams.append('page', i);
      promises.push(request(`${url.pathname}?${url.searchParams}`));
      url.searchParams.delete('page');
    }
    (await Promise.all(promises)).forEach((f) => first.data.push(...f.data));
  }
  return first.data;
};

const cleanObject = (obj) => Object.fromEntries(Object.entries(obj).filter(([_, v]) => v));

// CONSENTS
export const fetchConsents = (ref) => request(`/medicalfiles/${ref}/consents`);
export const fetchCompanyDocuments = (company) => request(`/companies/${company}/documents`);

// PROFILE
export const fetchProfile = (userId) => request(`/users/${userId}/profile`);
export const createProfile = (userId, values) =>
  request(`/users/${userId}/profile`, 'POST', {
    ...values,
    contact_type: 1,
    birthday: moment(values.birthday).format(moment.HTML5_FMT.DATE),
  });
export const updateProfile = (userId, values) =>
  request(`/users/${userId}/profile`, 'POST', {
    ...values,
    contact_type: 1,
    birthday: moment(values.birthday).format(moment.HTML5_FMT.DATE),
  });

// USERS
export const createUser = (values) => request('/users', 'POST', values);
export const updateUser = (userId, values) => request(`/users/${userId}`, 'PUT', values);
export const deleteUser = (userId) => request(`/users/${userId}`, 'DELETE');
export const fetchUsers = (page = 1, order) =>
  request(
    `/users?page=${page}${order ? `&sort=${order.property}&direction=${order.direction}` : ''}`
  );

export const fetchAllUsers = (role = null) => fetchAll(`/users`, [{ name: 'role', value: role }]);
export const fetchAllPatients = (userId) => fetchAll(`/users/${userId}/patients`);
export const fetchPatients = (userId, page = 1, order) =>
  request(
    `/users/${userId}/patients?page=${page}${
      order ? `&sort=${order.property}&direction=${order.direction}` : ''
    }`
  );

export const fetchContacts = (userId, page = 1, order) =>
  request(
    `/users/${userId}/contacts?page=${page}${
      order ? `&sort=${order.property}&direction=${order.direction}` : ''
    }`
  );

// COMPANIES
export const fetchCompanies = (page = 1) => request(`/companies?page=${page}`);
export const fetchAllCompanies = () => fetchAll('/companies');
export const createCompany = (values) => request('/companies', 'POST', values);
export const updateCompany = (id, values) => request(`/companies/${id}`, 'PUT', values);
export const deleteCompany = (id) => request(`/companies/${id}`, 'DELETE');

export const uploadCompanyDocument = async (company, values) => {
  const file = values.file.fileList[0];
  const formData = new FormData();
  formData.append('file', file.originFileObj);
  formData.append('type', values.type);
  await request(`/companies/${company}/documents`, 'POST', formData, true);
};

// ROLES
export const fetchAllRoles = () => fetchAll('/roles');

// MEDICAL FILES
export const fetchMedicalFiles = (page = 1, order, all = false) =>
  request(
    `/medicalfiles?page=${page}${
      order ? `&sort=${order.property}&direction=${order.direction}` : ''
    }&closed=${all}`
  );
export const fetchMedicalFile = (ref) => request(`/medicalfiles/${ref}`);
export const fetchMedicalActivities = (ref, page = 1) =>
  request(`/medicalfiles/${ref}/activity?page=${page}`);
export const fetchAllInterlocutors = (ref) => fetchAll(`/medicalfiles/${ref}/interlocutors`);
export const fetchAllUserMedicalFiles = (userId, page = 1) =>
  request(`/users/${userId}/medicalfiles?page=${page}`);

// FILE TYPES
export const fetchAllFileTypes = () => fetchAll('/filetypes');

// Documents
export const fetchUserDocuments = (userId, reference) =>
  fetchAll(`/users/${userId}/files${reference ? `?medical_file=${reference}` : ''}`);
export const fetchMedicalFilesDocuments = (ref) => fetchAll(`/medicalfiles/${ref}/files`);

// HEALTH FORM
export const fetchHealthQuestions = () => fetchAll('/healthquestions');
export const fetchHealthAnswers = (ref) => fetchAll(`/medicalfiles/${ref}/healthanswers`);
export const createHealthAnswers = (ref, values) =>
  request(`/medicalfiles/${ref}/healthanswers`, 'POST', values);

// FILE TRANSLATIONS
export const fetchFileTranslations = (ref, index, page = 1) =>
  request(`/medicalfiles/${ref}/files/${index}/translations?page=${page}`);
export const fetchSMOTranslations = (ref, index, page = 1) =>
  request(`/medicalfiles/${ref}/smo/${index}/translations?page=${page}`);
export const fetchAllSMOTranslations = (ref, index) =>
  fetchAll(`/medicalfiles/${ref}/smo/${index}/translations`);

// FILE DEMANDS
export const fetchAllFileDemands = (ref) => fetchAll(`/medicalfiles/${ref}/demands`);
export const fetchFileDemands = (ref, page = 1) =>
  request(`/medicalfiles/${ref}/demands?page=${page}`);
export const createFileDemand = (ref, values) =>
  request(`/medicalfiles/${ref}/demands`, 'POST', values);

// SMO
export const fetchSMO = (ref) => request(`/medicalfiles/${ref}/smo`);
export const createSMO = (ref, values) => request(`/medicalfiles/${ref}/smo`, 'POST', values);

// SATISFACTION FORM
export const fetchSatisfaction = () => fetchAll('/satisfactionquestions');
export const createSatisfactionAnswers = (ref, values) =>
  request(`/medicalfiles/${ref}/satisfactionanswers`, 'POST', values);
export const fetchSatisfactionQuestions = (ref) => fetchAll(`/satisfactionquestions`);
export const fetchSatisfactionAnswers = (ref) =>
  fetchAll(`/medicalfiles/${ref}/satisfactionanswers`);

// RATINGS
export const createRatings = (ref, values) =>
  request(`/medicalfiles/${ref}/ratings`, 'POST', values);

// WORKFLOW USER
export const requestWorkflowUser = (userId) => request(`/users/${userId}/workflow`);

// SEARCH
export const fetchSearchUser = (values, page = 1) =>
  request(`/patient/search?page=${page}&${queryString.stringify(cleanObject(values))}`, 'GET');
export const fetchSearchAllUsers = (values, page = 1) =>
  request(`/users/searchAll?page=${page}&${queryString.stringify(cleanObject(values))}`, 'GET');
export const fetchSearchMedicalFiles = (values, page = 1) =>
  request(`/medicalfiles/search?page=${page}&${queryString.stringify(cleanObject(values))}`, 'GET');

// FILES
export const fetchAllTranslations = (ref, fileType) =>
  request(`/medicalfiles/${ref}/files/all/${fileType}`);

// IMPERSONATE
export const loginAsPatient = (userId) => request(`/impersonate/${userId}`, 'POST');
export const logoutAsPatient = () => request(`/impersonate/leave`, 'POST');
