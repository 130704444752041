/* eslint-disable react/prop-types */
import { Form, Select } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import languages from '../../services/languages';

function SelectLanguages({ onlyEnglishAndFrench }) {
  const { t } = useTranslation();

  return (
    <Form.Item
      label={t('FRONT_USER_LANGUAGE')}
      name="language"
      rules={[{ required: true, message: t('FRONT_FORM_MESSAGE_REQUIRED') }]}
    >
      <Select
        filterOption={(input, option) =>
          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        showSearch
      >
        {languages
          .filter((l) => (onlyEnglishAndFrench ? l.value === 'en' || l.value === 'fr' : l))
          .map((l) => (
            <Select.Option key={l.value} value={l.value}>
              {t(l.label)}
            </Select.Option>
          ))}
      </Select>
    </Form.Item>
  );
}

export default SelectLanguages;
