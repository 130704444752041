import { Form, Input, Select } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import mobileCodes from '../../services/mobileCodes';
import Flex from '../Flex';

function MobilePhone() {
  const { t } = useTranslation();

  return (
    <Form.Item label={t('FRONT_USER_MOBILE_PHONE')} required>
      <Input.Group compact>
        <Flex>
          <Form.Item
            name="phone_country"
            rules={[{ required: true, message: t('FRONT_FORM_MESSAGE_REQUIRED') }]}
            noStyle
          >
            <Select
              style={{ width: 200 }}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              showSearch
            >
              {mobileCodes.map((m) => (
                <Select.Option key={m.dial_code} value={m.code}>
                  {`${m.name} (${m.dial_code})`}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="phone"
            rules={[{ required: true, message: t('FRONT_FORM_MESSAGE_REQUIRED') }]}
            noStyle
          >
            <Input />
          </Form.Item>
        </Flex>
      </Input.Group>
    </Form.Item>
  );
}

export default MobilePhone;
