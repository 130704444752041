import frLocale from 'date-fns/locale/fr';
import enLocale from 'date-fns/locale/en-US';
import esLocale from 'date-fns/locale/es';
import arLocale from 'date-fns/locale/ar-DZ';
import ruLocale from 'date-fns/locale/ru';
import zhLocale from 'date-fns/locale/zh-CN';

const localeDate = {
  fr: frLocale,
  en: enLocale,
  es: esLocale,
  ar: arLocale,
  ru: ruLocale,
  zh: zhLocale,
};

export default localeDate;
