/* eslint-disable react/prop-types */
import { Form, Select } from 'antd';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useRolesCreate } from '../../redux/metadata/selectors';
import { useRoles, useUser } from '../../redux/user/selectors';
import {
  isCoordinator,
  isDoctor,
  isExpertCoordinator,
  isExpertDoctor,
  isHubCoordinator,
  isHubDoctor,
  isLocalCoordinator,
  isLocalDoctor,
  isSuperAdmin,
} from '../../services/rights';

function SelectRoles({ condition = false }) {
  const { t } = useTranslation();
  const rolesCreate = useRolesCreate();
  const roles = useRoles();

  const filterRoles = useMemo(() => {
    const rr = [...roles];
    if (isLocalCoordinator(roles) && !isLocalDoctor(roles))
      rr.push(rolesCreate.find((rrr) => rrr.id === 4));
    if (isHubCoordinator(roles) && !isHubDoctor(roles))
      rr.push(rolesCreate.find((rrr) => rrr.id === 6));
    if (isExpertCoordinator(roles) && !isExpertDoctor(roles))
      rr.push(rolesCreate.find((rrr) => rrr.id === 8));
    return rr;
  }, [roles, rolesCreate]);

  const r = condition && isCoordinator(roles) ? filterRoles : rolesCreate;

  return (
    <Form.Item
      label={t('FRONT_USER_ROLE')}
      name="roles"
      rules={[{ required: true, message: t('FRONT_FORM_MESSAGE_REQUIRED') }]}
    >
      <Select mode="multiple">
        {r.map((m) => (
          <Select.Option key={`role-${m.id}`} value={m.id}>
            {m.alias}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  );
}

export default SelectRoles;
