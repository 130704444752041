import React from 'react';
import WorkflowUser from '../components/WorkflowUser';

function Dashboard() {
  return (
    <>
      <WorkflowUser />
    </>
  );
}

export default Dashboard;
