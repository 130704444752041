/* eslint-disable react/prop-types */
import { Form, Select } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useCountries } from '../../redux/metadata/selectors';

function SelectCountries({ multiple = true }) {
  const { t } = useTranslation();
  const countries = useCountries();

  return (
    <Form.Item
      label={t(multiple ? 'FRONT_USER_COUNTRIES' : 'FRONT_USER_COUNTRY')}
      name="countries"
      rules={[{ required: true, message: t('FRONT_FORM_MESSAGE_REQUIRED') }]}
    >
      <Select
        mode={multiple ? 'multiple' : ''}
        filterOption={(input, option) =>
          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        showSearch
      >
        {countries.map((c) => (
          <Select.Option key={`country-${c.id}`} value={c.id}>
            {c.name}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  );
}

export default SelectCountries;
