import { Form, Input, InputNumber, Radio } from 'antd';
import React from 'react';
import PropTypes from 'prop-types';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { useTranslation } from 'react-i18next';
import useLocaleDate from '../../hooks/useLocaleDate';

const { TextArea } = Input;

function FormDynamic({ questions, responses, satisfaction, disabled }) {
  const { t } = useTranslation();
  const localeDate = useLocaleDate();

  const renderItem = (q) => {
    const initialValue = satisfaction
      ? responses.find((r) => r.satisfaction_question_id === q.id)?.answer
      : responses.find((r) => r.health_question_id === q.id)?.answer;

    if (q.type === 'date')
      return (
        <MuiPickersUtilsProvider utils={DateFnsUtils} key={q.id} locale={localeDate}>
          <Form.Item
            initialValue={initialValue}
            label={q.question}
            name={q.id}
            key={q.id}
            rules={[{ required: q.required, message: t('FRONT_FORM_MESSAGE_REQUIRED') }]}
          >
            <KeyboardDatePicker
              margin="none"
              id="date-picker-dialog"
              format="dd/MM/yyyy"
              disabled={disabled}
            />
          </Form.Item>
        </MuiPickersUtilsProvider>
      );

    return (
      <Form.Item
        name={q.id}
        initialValue={initialValue}
        key={q.id}
        label={q.question}
        rules={[{ required: q.required, message: t('FRONT_FORM_MESSAGE_REQUIRED') }]}
      >
        {(() => {
          if (q.type === 'string') return <TextArea rows={2} disabled={disabled} />;
          if (q.type === 'integer') return <InputNumber disabled={disabled} />;
          if (q.type === 'boolean')
            return (
              <Radio.Group disabled={disabled}>
                <Radio value="1">{t('FRONT_COMMON_TRUE')}</Radio>
                <Radio value="0">{t('FRONT_COMMON_FALSE')}</Radio>
              </Radio.Group>
            );
          return null;
        })()}
      </Form.Item>
    );
  };

  return questions.map((q) => renderItem(q));
}

FormDynamic.propTypes = {
  questions: PropTypes.arrayOf(PropTypes.any).isRequired,
  responses: PropTypes.arrayOf(PropTypes.any).isRequired,
};

export default FormDynamic;
