/* eslint-disable react/no-unescaped-entities */
import React, { useEffect, useState } from 'react';
import { Button, Table } from 'antd';
import PropTypes from 'prop-types';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { fetchConsents } from '../../services/api';

function MedicalFileConsents({ medicalFile }) {
  const { t } = useTranslation();
  const history = useHistory();
  const [[documents, isFetching], setState] = useState([[], true]);

  useEffect(() => {
    async function getConsents() {
      try {
        const { data } = await fetchConsents(medicalFile.reference);
        setState([data, false]);
      } catch (err) {
        setState([[], false]);
      }
    }
    getConsents();
  }, [medicalFile]);

  const columns = [
    {
      title: t('FRONT_DOCUMENT_NAME'),
      dataIndex: 'name',
    },
    {
      title: t('FRONT_DOCUMENT_TYPE'),
      dataIndex: 'mime',
      render: (value) => value.split('/').pop().toUpperCase(),
    },
    {
      title: t('FRONT_MEDICAL_FILE_CONSENTS_CREATED_AT'),
      dataIndex: 'created_at',
      render: (value) => new Date(value).toLocaleString(),
    },
    {
      title: t('FRONT_MEDICAL_FILE_CONSENTS_CREATED_BY'),
      dataIndex: 'uploadedBy',
      render: (value) => `${value.firstname} ${value.lastname}`,
    },
    {
      dataIndex: 'index',
      render: (value, row) => (
        <Button
          type="primary"
          onClick={() =>
            history.push('/viewer/v2', {
              length: row.size,
              url: `/medicalfiles/${medicalFile.reference}/consents/${value}`,
            })
          }
        >
          {t('FRONT_DOCUMENT_SHOW')}
        </Button>
      ),
    },
  ];

  return (
    <>
      <Table rowKey="id" dataSource={documents} columns={columns} loading={isFetching} />
      <Trans
        i18nKey="FRONT_MEDICAL_FILE_CONSENTS"
        t={t}
        components={[
          <ul />,
          <>
            <li>
              Exercer <strong>Vos</strong> droits ;
            </li>
            <li>
              Retirer <strong>Votre</strong> consentement
            </li>
          </>,
          <a target="_blank" rel="noreferrer" href="mailto:smo@axa365.onmicrosoft.com">
            smo@axa365.onmicrosoft.com
          </a>,
          <a target="_blank" rel="noreferrer" href={t('FRONT_MEDICAL_FILE_EDPB')}>
            Comité Européen de la Protection des Données (EDPB) - Membres.
          </a>,
        ]}
      >
        Vous pouvez :
        <ul>
          <li>
            Exercer <strong>Vos</strong> droits ;
          </li>
          <li>
            Retirer <strong>Votre</strong> consentement
          </li>
        </ul>
        en <strong>Nous</strong> contactant à l'adresse électronique suivante :
        <a target="_blank" href="mailto:smo@axa365.onmicrosoft.com" rel="noreferrer">
          smo@axa365.onmicrosoft.com
        </a>
        <br />
        <br />
        Enfin, <strong>Vous</strong> avez le droit de faire part de toute préoccupation concernant
        la manière dont <strong>Vos</strong> données personnelles sont traitées à une autorité de
        contrôle compétente, en particulier dans l&apos;État membre de Votre résidence habituelle,
        de <strong>Votre</strong> lieu de travail ou du lieu où <strong>Vous</strong> pensez
        qu&apos;une violation présumée de <strong>Vos</strong> droits s&apos;est produite.
        <br />
        <br /> La liste des autorités de protection des données dans l&apos;Union européenne est
        disponible via le lien suivant :{' '}
        <a target="_blank" to={t('FRONT_MEDICAL_FILE_EDPB')}>
          Comité Européen de la Protection des Données (EDPB) - Membres.
        </a>
        <br />
        <br /> Pour les pays non-membres de l&apos;UE, veuillez consulter le site web de{' '}
        <strong>Votre</strong> autorité locale de protection des données.
      </Trans>
    </>
  );
}

MedicalFileConsents.propTypes = {
  medicalFile: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default MedicalFileConsents;
