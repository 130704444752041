/* eslint-disable react/prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import { Form, Checkbox, Button, Collapse, message } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import request from '../../services/request';
import Loading from '../layouts/Loading';

const { Panel } = Collapse;

function Notifications({ company }) {
  const { t } = useTranslation();
  const [notifications, setNotifications] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);

  const loadNotifications = async () => {
    setIsLoading(true);
    try {
      const data = await request(`/companies/${company}/notifications`);
      setNotifications(data);
    } catch (error) {
      message.error(error.message || 'Failed to fetch notifications');
    }
    setIsLoading(false);
  };

  const updateNotifications = async (data) => {
    const body = [];
    Object.entries(data).map((e) => {
      body.push({ name: e[0], value: e[1] });
      return data;
    });
    setIsUpdating(true);
    try {
      await request(`/companies/${company}/notifications`, 'POST', { notifications: body });
      message.success(t('FRONT_NOTIFICATION_SAVE_SUCCESS'));
    } catch (error) {
      message.error(error.message);
    }
    setIsUpdating(false);
  };

  useEffect(() => {
    loadNotifications();
  }, []);

  if (isLoading) {
    return <Loading />;
  }
  return (
    <Collapse style={{ marginBottom: 20 }}>
      <Panel header={t('FRONT_ENTITY_NOTIFICATIONS')} key="1">
        <Form onFinish={updateNotifications}>
          {notifications &&
            notifications.map((n) => (
              <Form.Item name={n.name} valuePropName="checked" initialValue={n.value}>
                <Checkbox>{n.alias}</Checkbox>
              </Form.Item>
            ))}
          <Button type="primary" htmlType="submit" loading={isUpdating}>
            {t('FRONT_FORM_SAVE')}
          </Button>
        </Form>
      </Panel>
    </Collapse>
  );
}

export default Notifications;
