import { SET_STEP, STEP_IS_FETCHING } from './actions';

const step = (state = [null, false], action) => {
  switch (action.type) {
    case SET_STEP:
      return [action.step, false];
    case STEP_IS_FETCHING:
      return [state[0], true];
    default:
      return state;
  }
};

export default step;
