/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import DocumentCreate from '../../components/documents/Create';
import UserDocumentsList from '../../components/UserDocumentsList';
import useHasPermission from '../../hooks/useHasPermission';
import {
  createDocuments,
  fetchDocuments,
  isFetching as isDocumentFetching,
} from '../../redux/documents/actions';
import { useDocuments } from '../../redux/documents/selectors';
import { useMedicalFile, usePatient } from '../../redux/medicalFile/selectors';
import { fetchStep } from '../../redux/step/actions';
import { useUser } from '../../redux/user/selectors';

function MedicalFileDocumentsUser() {
  const dispatch = useDispatch();
  const [medicalFile] = useMedicalFile();
  const hasPermission = useHasPermission();
  const [documents, isFetching] = useDocuments();
  const [user] = useUser();
  const [patient] = usePatient();

  useEffect(() => {
    dispatch(fetchDocuments(medicalFile.reference, patient.id));
  }, [dispatch, patient.id, medicalFile.reference]);

  return (
    <>
      {hasPermission('create users files') && (
        <DocumentCreate
          onSave={async (values) => {
            dispatch(isDocumentFetching());
            await createDocuments(medicalFile, patient.id, values, medicalFile);
            dispatch(fetchDocuments());
            dispatch(fetchStep(medicalFile.reference));
          }}
          isAdmin
          reference={medicalFile.reference}
        />
      )}
      <UserDocumentsList documents={documents} isFetching={isFetching} userId={patient.id} />
    </>
  );
}

export default MedicalFileDocumentsUser;
