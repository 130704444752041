import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { Form, Input, InputNumber } from 'antd';
import React from 'react';
import DateFnsUtils from '@date-io/date-fns';
import { useTranslation } from 'react-i18next';
import MobilePhone from './form/MobilePhone';
import SelectCountry from './form/SelectCountry';
import { useRoles } from '../redux/user/selectors';
import { isPatient } from '../services/rights';
import useLocaleDate from '../hooks/useLocaleDate';

function Profile() {
  const { t } = useTranslation();
  const roles = useRoles();
  const localeDate = useLocaleDate();

  return (
    <>
      <MobilePhone />
      <Form.Item label={t('FRONT_USER_ADDRESS')} name="address">
        <Input />
      </Form.Item>
      <Form.Item label={t('FRONT_USER_ZIP')} name="zipcode">
        <Input />
      </Form.Item>
      <Form.Item label={t('FRONT_USER_CITY')} name="city">
        <Input />
      </Form.Item>

      <SelectCountry />

      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={localeDate}>
        <Form.Item
          label={t('FRONT_USER_BIRTH_DATE')}
          name="birthday"
          rules={[{ required: true, message: t('FRONT_FORM_MESSAGE_REQUIRED') }]}
        >
          <KeyboardDatePicker margin="none" id="date-picker-dialog" format="dd/MM/yyyy" />
        </Form.Item>
      </MuiPickersUtilsProvider>

      <Form.Item label={t('FRONT_USER_PATHOLOGY')} name="pathology">
        <Input />
      </Form.Item>
    </>
  );
}

export default Profile;
