import { FETCH_DOCUMENTS_IS_FETCHING, SET_DOCUMENTS } from './actions';

const documents = (state = [null, true], action) => {
  switch (action.type) {
    case SET_DOCUMENTS:
      return [action.documents, false];
    case FETCH_DOCUMENTS_IS_FETCHING:
      return [null, true];
    default:
      return state;
  }
};

export default documents;
