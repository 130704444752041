/* eslint-disable react/prop-types */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Switch } from 'antd';

function SwitchConsent({ label, name, required = true }) {
  const { t } = useTranslation();

  return (
    <Form.Item
      label={
        <label style={{ whiteSpace: 'pre-wrap' }} htmlFor={name}>
          {label}
        </label>
      }
      name={name}
      valuePropName="checked"
      rules={[
        {
          validator: (_, checked) => (checked ? Promise.resolve() : Promise.reject()),
          required,
          message: t('FRONT_FORM_MESSAGE_REQUIRED'),
        },
      ]}
    >
      <Switch />
    </Form.Item>
  );
}

export default SwitchConsent;
