/* eslint-disable react/prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import { DeleteOutlined, InboxOutlined } from '@ant-design/icons';
import { Form, Checkbox, Button, Collapse, message, Select, Table, Space, Input } from 'antd';
import Dragger from 'antd/lib/upload/Dragger';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import acceptFileType from '../../services/AcceptFileType';
import { fetchCompanyDocuments, uploadCompanyDocument } from '../../services/api';
import request, { url } from '../../services/request';

const styles = { icon: { fontSize: 18, color: 'red', cursor: 'pointer' } };

function CompanyConsents({ company }) {
  const { t } = useTranslation();
  const history = useHistory();
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [consents, setConsents] = useState([]);

  const documentTypes = [
    { value: 'privacy', label: t('FRONT_COMPANY_DOCUMENTS_PRIVACY') },
    { value: 'smo_consents', label: t('FRONT_COMPANY_DOCUMENTS_SMO') },
    { value: 'tos', label: t('FRONT_COMPANY_DOCUMENTS_TOS') },
  ];

  const getDocuments = useCallback(async () => {
    try {
      const { data } = await fetchCompanyDocuments(company);
      setConsents(data);
    } catch (err) {
      setConsents([]);
    }
  }, [company]);

  const handleRemoveDocument = async (index) => {
    setIsLoading(true);
    try {
      await request(`/companies/${company}/documents/${index}`, 'DELETE');
      await getDocuments();
    } catch (error) {
      message.error('Failed to remove');
    }
    setIsLoading(false);
  };

  const columns = [
    {
      title: t('FRONT_DOCUMENT_TYPE'),
      dataIndex: 'type',
      render: (value) => t(`FRONT_COMPANY_DOCUMENTS_${value.toUpperCase()}`),
    },
    { title: t('FRONT_DOCUMENT_SIZE'), dataIndex: 'size' },
    {
      title: t('FRONT_COMMON_UPDATED_AT'),
      dataIndex: 'updated_at',
      render: (value) => new Date(value).toLocaleString(),
    },
    {
      key: 'action',
      render: (file) => (
        <Space>
          <Button
            type="primary"
            onClick={() =>
              history.push('/viewer/v2', {
                length: file.size,
                url: `/companies/${company}/documents/${file.index}`,
              })
            }
            disabled={!acceptFileType(file)}
          >
            {t('FRONT_DOCUMENT_SHOW')}
          </Button>
          <Button type="primary">
            <a
              target="_blank"
              rel="noreferrer"
              href={`${url}/companies/${company}/documents/${file.index}}`}
            >
              {t('FRONT_COMMON_DOWNLOAD')}
            </a>
          </Button>

          <DeleteOutlined onClick={() => handleRemoveDocument(file.index)} style={styles.icon} />
        </Space>
      ),
    },
  ];

  useEffect(() => {
    getDocuments();
  }, [getDocuments]);

  const onSave = async (values) => {
    setIsLoading(true);
    try {
      await uploadCompanyDocument(company, values);
      await getDocuments();
      form.resetFields();
    } catch (error) {
      message.error('Failed to upload');
    }
    setIsLoading(false);
  };

  return (
    <Collapse style={{ marginBottom: 15 }}>
      <Collapse.Panel header={t('FRONT_COMPANY_DOCUMENTS_SELECT')}>
        <Form onFinish={onSave} form={form}>
          <Form.Item
            name="type"
            label={t('FRONT_DOCUMENT_SELECT_TYPE')}
            rules={[{ required: true, message: t('FRONT_FORM_MESSAGE_REQUIRED') }]}
          >
            <Select placeholder="Select the document to change" options={documentTypes} />
          </Form.Item>
          <Form.Item
            name="file"
            rules={[{ required: true, message: t('FRONT_FORM_MESSAGE_REQUIRED') }]}
          >
            <Dragger beforeUpload={() => false}>
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">{t('FRONT_DOCUMENT_FILE_UPLOAD')}</p>
            </Dragger>
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              {t('FRONT_FORM_SAVE')}
            </Button>
          </Form.Item>
        </Form>
        <Table rowKey="id" dataSource={consents} columns={columns} loading={isLoading} />
      </Collapse.Panel>
    </Collapse>
  );
}

export default CompanyConsents;
