/* eslint-disable react/prop-types */
import { InboxOutlined } from '@ant-design/icons';
import { Button, Form, Input, Space, Table } from 'antd';
import Dragger from 'antd/lib/upload/Dragger';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Flex from '../../../components/Flex';
import FormErrors from '../../../components/form/FormErrors';
import TableCustom from '../../../components/Table';
import useHasPermission from '../../../hooks/useHasPermission';
import useStepActive from '../../../hooks/useStepActive';
import { fetchStep } from '../../../redux/step/actions';
import { useRoles } from '../../../redux/user/selectors';
import acceptFileType from '../../../services/AcceptFileType';
import { fetchSMO } from '../../../services/api';
import request, { url } from '../../../services/request';
import { isExpertDoctor, isHubDoctor, isLocalDoctor } from '../../../services/rights';

function SMO({ reference }) {
  const { t } = useTranslation();
  const ref = useRef();
  const history = useHistory();
  const dispatch = useDispatch();
  const [[state, isFetching], setState] = useState([[], true]);
  const [[isCreating, errors], setCreate] = useState([false, null]);
  const hasPermission = useHasPermission();
  const roles = useRoles();
  const { isStep, beforeStep } = useStepActive();

  const fetchData = useCallback(
    (page) =>
      fetchSMO(reference, page)
        .then((s) => setState([s.data, false]))
        .catch(() => setState([[], false])),
    [reference]
  );

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const create = async (values) => {
    setCreate([true, null]);
    try {
      const file = values.file.fileList[0];
      const formData = new FormData();
      formData.append('file', file.originFileObj);
      formData.append('name', values.name);
      await request(`/medicalfiles/${reference}/smo`, 'POST', formData, true);
      await fetchData();
      dispatch(fetchStep(reference));
      if (ref.current) ref.current.resetFields();
      setCreate([false, null]);
    } catch (err) {
      setCreate([false, err]);
    }
  };

  const columns = [
    { title: t('FRONT_DOCUMENT_NAME'), dataIndex: 'name' },
    { title: t('FRONT_DOCUMENT_SIZE'), dataIndex: 'size' },
    {
      title: t('FRONT_COMMON_UPDATED_AT'),
      dataIndex: 'updated_at',
      render: (value) => new Date(value).toLocaleString(),
    },
    {
      title: t('FRONT_DOCUMENT_SMO_UPLOAD_BY_FIRST_NAME'),
      dataIndex: ['uploadedBy', 'firstname'],
    },
    {
      title: t('FRONT_DOCUMENT_SMO_UPLOAD_BY_LAST_NAME'),
      dataIndex: ['uploadedBy', 'lastname'],
    },
    {
      key: 'action',
      render: (value) => (
        <Space>
          <Button
            type="primary"
            onClick={() =>
              history.push('/viewer/v2', { url: `/medicalfiles/${reference}/smo/${value.index}` })
            }
            disabled={!acceptFileType(value)}
          >
            {t('FRONT_DOCUMENT_SHOW')}
          </Button>

          <Button type="primary">
            <a
              target="_blank"
              rel="noreferrer"
              href={`${url}/medicalfiles/${reference}/smo/${value?.index}`}
            >
              {t('FRONT_COMMON_DOWNLOAD')}
            </a>
          </Button>

          {hasPermission('update smo files') && (
            <Button
              type="primary"
              onClick={() => history.push(`/medicals/${reference}/smo/${value.index}/edit`)}
            >
              {t('FRONT_DOCUMENT_UPDATE')}
            </Button>
          )}
          {isLocalDoctor(roles) && (
            <Button
              type="primary"
              onClick={() =>
                history.push(`/medicals/${reference}/smo/${value.index}/translations/add`)
              }
            >
              {t('FRONT_DOCUMENT_TRADUCTIONS')}
            </Button>
          )}
        </Space>
      ),
    },
  ];

  return (
    <>
      {isLocalDoctor(roles) && isStep('localdoctor.stepvalidatesmo') && (
        <Flex style={{ marginBottom: 10 }}>
          <Button type="primary" size="large" onClick={() => dispatch(fetchStep(reference, true))}>
            {t('FRONT_MEDICAL_FILE_VALIDATE_SMO')}
          </Button>
        </Flex>
      )}
      {isHubDoctor(roles) && isStep('hubdoctor.stepvalidatesmo') && (
        <Flex style={{ marginBottom: 10 }}>
          <Button
            type="primary"
            size="large"
            onClick={() => history.push(`/medicals/${reference}/ratings`)}
          >
            {t('FRONT_MEDICAL_FILE_VALIDATE_SMO')}
          </Button>
        </Flex>
      )}
      {hasPermission('create smo files') &&
        ((isExpertDoctor(roles) && beforeStep('expertdoctor.stepdosmo')) ||
          (isHubDoctor(roles) && beforeStep('hubdoctor.stepvalidatesmo'))) && (
          <>
            <Form ref={ref} layout="vertical" onFinish={create}>
              <Form.Item
                name="name"
                label={t('FRONT_DOCUMENT_NAME')}
                rules={[{ required: true, message: t('FRONT_FORM_MESSAGE_REQUIRED') }]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                name="file"
                rules={[{ required: true, message: t('FRONT_FORM_MESSAGE_REQUIRED') }]}
              >
                <Dragger beforeUpload={() => false} multiple>
                  <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                  </p>
                  <p className="ant-upload-text">{t('FRONT_FORM_MESSAGE_REQUIRED')}</p>
                </Dragger>
              </Form.Item>

              <FormErrors err={errors} />

              <Form.Item>
                <Button type="primary" htmlType="submit" loading={isCreating}>
                  {t('FRONT_FORM_SAVE')}
                </Button>
              </Form.Item>
            </Form>
          </>
        )}
      <Table rowKey="id" dataSource={state} columns={columns} loading={isFetching} />
    </>
  );
}

export default SMO;
