import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import DocumentCreate from '../../components/documents/Create';
import UserDocumentsList from '../../components/UserDocumentsList';
import WorkflowUser from '../../components/WorkflowUser';
import {
  createDocuments,
  fetchDocuments,
  isFetching as isDocumentFetching,
} from '../../redux/documents/actions';
import { useDocuments } from '../../redux/documents/selectors';
import { useUser } from '../../redux/user/selectors';
import { fetchWorlflowUser } from '../../redux/workflowUser/actions';

function UserDocuments() {
  const dispatch = useDispatch();
  const [documents, isFetching] = useDocuments();
  const [user] = useUser();
  const history = useHistory();

  useEffect(() => {
    dispatch(fetchDocuments());
  }, [dispatch]);

  return (
    <>
      <WorkflowUser />
      <DocumentCreate
        onSave={async (values) => {
          dispatch(isDocumentFetching());
          await createDocuments(null, user.id, values);
          dispatch(fetchWorlflowUser());
          if (documents.length === 0) history.push('/medicals');
        }}
        isAdmin
      />
      <UserDocumentsList documents={documents} isFetching={isFetching} userId={user.id} />
    </>
  );
}

export default UserDocuments;
