import { Button } from 'antd';
import SkeletonButton from 'antd/lib/skeleton/Button';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useRoles } from '../redux/user/selectors';
import { fetchWorlflowUser } from '../redux/workflowUser/actions';
import { useWorkflowUser } from '../redux/workflowUser/selectors';
import { isPatient } from '../services/rights';
import Flex from './Flex';

function WorkflowUser() {
  const dispatch = useDispatch();
  const history = useHistory();
  const [workflow, isFetching] = useWorkflowUser();
  const roles = useRoles();

  useEffect(() => {
    dispatch(fetchWorlflowUser());
  }, [dispatch]);

  if (isFetching) return <SkeletonButton />;

  if (!isPatient(roles)) return null;

  const steps = () => {
    if (workflow.step === 'fill_profile')
      return (
        <Button type="primary" size="large" onClick={() => history.push('/profile')}>
          {workflow.description}
        </Button>
      );

    if (workflow.step === 'upload_ID')
      return (
        <Button type="primary" size="large" onClick={() => history.push('/documents')}>
          {workflow.description}
        </Button>
      );

    if (workflow.step === 'create_medical_file')
      return (
        <Button type="primary" size="large" onClick={() => history.push('/medicals/add')}>
          {workflow.description}
        </Button>
      );

    if (workflow.step === 'complete_medical_file')
      return (
        <Button type="primary" size="large" onClick={() => history.push('/medicals')}>
          {workflow.description}
        </Button>
      );

    return null;
  };

  return <Flex style={{ marginBottom: 10 }}>{steps()}</Flex>;
}

export default WorkflowUser;
