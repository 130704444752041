import { Button, Form, Input, message } from 'antd';
import Title from 'antd/lib/typography/Title';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import FormErrors from '../../components/form/FormErrors';
import SelectLanguages from '../../components/form/SelectLanguages';
import WorkflowUser from '../../components/WorkflowUser';
import { useUser } from '../../redux/user/selectors';
import { updateUser } from '../../services/api';

function UserAccount() {
  const { t } = useTranslation();
  const [state, setState] = useState({ isFetching: false, err: null });
  const [user] = useUser();

  const update = async (values) => {
    setState({ isFetching: true, err: null });
    try {
      await updateUser(user.id, values);
      setState({ isFetching: false, err: null });
      message.success(t('FRONT_NOTIFICATION_UPDATE_SUCCESS'));
      window.location.reload(false);
    } catch (err) {
      setState({ isFetching: false, err });
    }
  };

  return (
    <div>
      <WorkflowUser />
      <Title>{t('FRONT_SIDEBAR_ACCOUNT')}</Title>
      <Form
        layout="vertical"
        name="account"
        onFinish={update}
        initialValues={user}
        scrollToFirstError
      >
        <Form.Item
          label={t('FRONT_USER_EMAIL_FOR_LOGIN')}
          name="email"
          rules={[{ type: 'email', required: true, message: t('FRONT_FORM_MESSAGE_REQUIRED') }]}
        >
          <Input />
        </Form.Item>

        <SelectLanguages />

        {state.err && <FormErrors err={state.err} />}

        <Form.Item>
          <Button type="primary" htmlType="submit" loading={state.isFetching}>
            {t('FRONT_FORM_SAVE')}
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}

export default UserAccount;
