const HKSMOSTEPS = Object.freeze({
  ASSIGN_TO_LOCAL_COORDINATOR: 'any.stepassigntolocalcoordinator',
  FILL_CONTACT: 'patient.stepfillcontact',
  UPLOAD_ADMIN_DOCUMENTS: 'patient.stepuploadadmindocuments',
  FILL_MEDICAL_FORM: 'patient.stepfillmedicalform',
  ASSIGN_TO_LOCAL_DOCTOR: 'localcoordinator.stepassigntolocaldoctor',
  WAITING_FOR_INTERVIEW: 'any.waitingforinterview',
  ASK_MEDICAL_FILES: 'localdoctor.stepaskmedicalfiles',
  UPLOAD_MEDICAL_FILES: 'patient.stepuploadmedicalfiles',
  LD_VALIDATE_MEDICAL_FILE: 'localdoctor.stepvalidatemedicalfile',
  ASSIGN_TO_HUB_COORDINATOR: 'localcoordinator.stepassigntohubcoordinator',
  ASSIGN_TO_HUB_DOCTOR: 'hubcoordinator.stepassigntohubdoctor',
  HD_VALIDATE_MEDICAL_FILE: 'hubdoctor.stepvalidatemedicalfile',
  ASSIGN_TO_EXPERT_COORDINATOR: 'hubcoordinator.stepassigntoexpertcoordinator',
  ASSIGN_TO_EXPERT_DOCTOR: 'expertcoordinator.stepassigntoexpertdoctor',
  INITIATE_SMO: 'expertdoctor.stepinitiatesmo',
  DO_SMO: 'expertdoctor.stepdosmo',
  HD_VALIDATE_SMO: 'hubdoctor.stepvalidatesmo',
  LD_VALIDATE_SMO: 'localdoctor.stepvalidatesmo',
  VIEW_SMO: 'patient.viewsmo',
  FILL_SATISFACTION_FORM: 'patient.stepfillsatisfactionform',
  FILE_CLOSED: 'any.fileclosed',
});

export default HKSMOSTEPS;
