import { message } from 'antd';
import { fetchMedicalFilesDocuments, fetchUserDocuments } from '../../services/api';
import request from '../../services/request';
import { fetchStep } from '../step/actions';

export const SET_DOCUMENTS = 'SET_DOCUMENTS';
export const FETCH_DOCUMENTS_IS_FETCHING = 'FETCH_DOCUMENTS_IS_FETCHING';

const setDocuments = (documents) => ({ type: SET_DOCUMENTS, documents });
export const isFetching = () => ({ type: FETCH_DOCUMENTS_IS_FETCHING });

export const fetchDocuments = (reference, userId) => async (dispatch, getState) => {
  dispatch(isFetching());
  try {
    const files =
      reference && userId == null
        ? await fetchMedicalFilesDocuments(reference)
        : await fetchUserDocuments(userId || getState().user[0].id, reference);
    dispatch(setDocuments(files));
  } catch {
    dispatch(setDocuments([]));
  }
};

const upload = async (medicalFile, userId, file, values) => {
  const formData = new FormData();
  formData.append('file', file.originFileObj);
  formData.append('name', values.name);
  formData.append('file_type_id', values.file_type_id);
  if (medicalFile && !userId) formData.append('medical_file_id', medicalFile.id);
  return medicalFile && !userId
    ? request(`/medicalfiles/${medicalFile.reference}/files`, 'POST', formData, true)
    : request(
        `/users/${userId}/files${medicalFile ? `?medical_file=${medicalFile.reference}` : ''}`,
        'POST',
        formData,
        true
      );
};

export const createDocuments = async (medicalFile, userId, values) => {
  if (values.postal) {
    const v = { ...values, postal_date: new Date(), postal: 1 };
    if (medicalFile && !userId)
      await request(`/medicalfiles/${medicalFile.reference}/files`, 'POST', v);
    else await request(`/users/${userId}/files`, 'POST', v);
  } else {
    const files = values.file.fileList;
    await Promise.all(files.map((f) => upload(medicalFile, userId, f, values)));
  }
};

export const removeDocument = (reference, userId, uuid) => async (dispatch) => {
  dispatch(isFetching());
  try {
    if (reference) await request(`/medicalfiles/${reference}/files/${uuid}`, 'DELETE');
    if (userId) await request(`/users/${userId}/files/${uuid}`, 'DELETE');
    await dispatch(fetchDocuments(reference));
    message.success('Deleted with success');
  } catch {
    message.error('Deleted has failed');
  }
};
