import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import Dashboard from '../../pages/Dashboard';
import EntityCreate from '../../pages/entities/Create';
import EntitiesList from '../../pages/entities/List';
import FileTraduction from '../../pages/FileTraduction';
import FinalSMO from '../../pages/FinalSMO';
import ConsentSMO from '../../pages/informations/ConsentSMO';
import FAQ from '../../pages/informations/FAQ';
import LegalTerms from '../../pages/informations/LegalTerms';
import PrivacyPolicy from '../../pages/informations/PrivacyPolicy';
import MedicalFile from '../../pages/medicalFile';
import MedicalFileRatings from '../../pages/medicalFile/Ratings';
import MedicalFileCreate from '../../pages/medicalFiles/Create';
import MedicalFilesList from '../../pages/medicalFiles/List';
import PatientCreate from '../../pages/patients/Create';
import PatientsList from '../../pages/patients/List';
import SearchAllUsers from '../../pages/SearchAllUsers';
import SearchMedicalFile from '../../pages/SearchMedicalFile';
import SearchUser from '../../pages/SearchUser';
import TranslationSMO from '../../pages/TranslationsSMO';
import UpdateSMO from '../../pages/UpdateSMO';
import UserAccount from '../../pages/user/Account';
import MyDocuments from '../../pages/user/Documents';
import UserProfile from '../../pages/user/Profile';
import UserProfileV2 from '../../pages/UserProfile';
import CreateUser from '../../pages/users/Create';
import ListUsers from '../../pages/users/List';
import UserMedicalFiles from '../../pages/users/MedicalFiles';
import UserContacts from '../../pages/users/Contacts';
import Viewer from '../../pages/viewer/Viewer';
import Error404 from '../Error404';

const routes = [
  { path: '/', component: <Redirect to="/medicals" /> },
  { path: '/profile', component: <UserProfile /> },
  { path: '/account', component: <UserAccount /> },
  { path: '/faq', component: <FAQ /> },
  { path: '/policy', component: <PrivacyPolicy /> },
  { path: '/terms', component: <LegalTerms /> },
  { path: '/users/:userId/medicals', component: <UserMedicalFiles /> },
  { path: '/users/:userId/contacts', component: <UserContacts /> },
  { path: '/users', component: <ListUsers /> },
  { path: '/users/search', component: <SearchAllUsers /> },
  { path: '/medicals/search', component: <SearchMedicalFile /> },
  { path: '/patients/search', component: <SearchUser /> },
  { path: '/entities', component: <EntitiesList /> },
  { path: '/entities/add', component: <EntityCreate /> },
  { path: '/consent_smo', component: <ConsentSMO /> },
  { path: '/users/add', component: <CreateUser /> },
  { path: '/medicals', component: <MedicalFilesList /> },
  { path: '/medicals/add', component: <MedicalFileCreate /> },
  { path: '/documents', component: <MyDocuments /> },
  { path: '/patients', component: <PatientsList /> },
  { path: '/patients/add', component: <PatientCreate /> },
  { path: '/viewer', component: <Viewer /> },
  { path: '/viewer/v2', component: <Viewer /> },
  { path: '/medicals/:reference/smo/:smo/edit', component: <UpdateSMO /> },
  { path: '/medicals/:reference/smo/:smo/edit', component: <UpdateSMO /> },
  { path: '/medicals/:reference/smo/final', component: <FinalSMO /> },
  { path: '/medicals/:reference/smo/:index/translations/add', component: <TranslationSMO /> },
  { path: '/medicals/:reference/ratings', component: <MedicalFileRatings /> },
  { path: '/medicals/:reference', component: <MedicalFile />, layout: true },
  { path: '/translation', component: <FileTraduction /> },
  { path: '/dashboard', component: <Dashboard /> },
  { path: '/profile/:userId/edit', component: <UserProfileV2 /> },
];

function RouterAXA() {
  return (
    <Switch>
      {routes.map((r) => (
        <Route key={Math.random()} path={r.path} exact={!r.layout}>
          {r.component}
        </Route>
      ))}
      <Route>
        <Error404 />
      </Route>
    </Switch>
  );
}

export default RouterAXA;
