import { Button, Layout } from 'antd';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { fetchUser } from '../../redux/user/actions';
import Loading from './Loading';
import HeaderAXA from './Header';
import SiderAXA from './Sider';
import { logoutAsPatient } from '../../services/api';

const { Content } = Layout;

function LayoutPrivate({ children }) {
  const dispatch = useDispatch();
  const params = useParams();
  const { t } = useTranslation();
  const [user, isFetching] = useSelector((state) => state.user);

  const { token, email } = params; // utilisateur temporaire sans session

  useEffect(() => {
    dispatch(fetchUser());
  }, [dispatch]);

  if (isFetching) return <Loading />;
  if (!user) return <Redirect to="/login" />;

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <HeaderAXA />

      <Layout className="content" hasSider>
        <SiderAXA />
        <Layout>
          <Content style={{ padding: 24 }}>
            <>
              {user.impersonated && (
                <Button
                  style={{ marginBottom: 10 }}
                  type="primary"
                  onClick={() => logoutAsPatient().then(() => dispatch(fetchUser()))}
                >
                  {t('FRONT_USER_LOGOUT_FROM_PATIENT')}
                </Button>
              )}
              {children}
            </>
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
}

LayoutPrivate.propTypes = {
  children: PropTypes.element.isRequired,
};

export default LayoutPrivate;
