/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
import { Button, Space, Table } from 'antd';
import Title from 'antd/lib/typography/Title';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import acceptFileType from '../services/AcceptFileType';
import { fetchAllSMOTranslations, fetchSMO } from '../services/api';
import { url } from '../services/request';

function FinalSMO() {
  const { t } = useTranslation();
  const history = useHistory();
  const params = useParams();
  const [[smo, SMOisFetching], setState] = useState([[], true]);
  const [[translations, translationsIsFetching], setTranslations] = useState([[], true]);

  const reference = params?.reference;

  const fetchData = useCallback(async () => {
    if (!reference || smo.length === 0) {
      setTranslations([[], false]);
    }
    const allTranslations = [];
    await Promise.all(
      smo.map(async (s) => {
        const data = await fetchAllSMOTranslations(reference, s.index);
        if (!data || data.length === 0) return;
        data.map((d, index) => {
          // eslint-disable-next-line no-param-reassign
          d.name = `${s.name} (${index + 1})`;
          return d;
        });
        allTranslations.push(...data);
      })
    );
    setTranslations([allTranslations, false]);
  }, [reference, smo]);

  useEffect(() => {
    fetchSMO(reference)
      .then((s) => setState([s.data, false]))
      .catch(() => setState([null, false]));
  }, [reference]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const columns = [
    { title: t('FRONT_DOCUMENT_NAME'), dataIndex: 'name' },
    { title: t('FRONT_DOCUMENT_SIZE'), dataIndex: 'size' },
    {
      title: t('FRONT_COMMON_UPDATED_AT'),
      dataIndex: 'updated_at',
      render: (value) => new Date(value).toLocaleString(),
    },
    {
      key: 'action',
      render: (value) => (
        <Space>
          <Button
            type="primary"
            onClick={() =>
              history.push('/viewer/v2', { url: `/medicalfiles/${reference}/smo/${value.index}` })
            }
            disabled={!acceptFileType(value)}
          >
            {t('FRONT_DOCUMENT_SHOW')}
          </Button>
          <Button type="primary">
            <a
              target="_blank"
              rel="noreferrer"
              href={`${url}/medicalfiles/${reference}/smo/${value.index}`}
            >
              {t('FRONT_COMMON_DOWNLOAD')}
            </a>
          </Button>
        </Space>
      ),
    },
  ];

  const columns2 = [
    { title: t('FRONT_DOCUMENT_NAME'), dataIndex: 'name' },
    { title: t('FRONT_DOCUMENT_SIZE'), dataIndex: 'size' },
    {
      title: t('FRONT_COMMON_UPDATED_AT'),
      dataIndex: 'updated_at',
      render: (value) => new Date(value).toLocaleString(),
    },
    {
      key: 'action',
      render: (value) => (
        <Space>
          <Button
            type="primary"
            onClick={() =>
              history.push('/viewer/v2', {
                url: `/translations/${value.index}`,
              })
            }
            disabled={!acceptFileType(value)}
          >
            {t('FRONT_DOCUMENT_SHOW')}
          </Button>
          <Button type="primary">
            <a target="_blank" rel="noreferrer" href={`${url}/translations/${value.index}`}>
              {t('FRONT_COMMON_DOWNLOAD')}
            </a>
          </Button>
        </Space>
      ),
    },
  ];

  return (
    <>
      <Button style={{ marginBottom: 10 }} type="primary" onClick={() => history.goBack()}>
        {t('FRONT_COMMON_BACK')}
      </Button>
      <Title level={4}>{t('FRONT_SMO_FINAL')}</Title>
      <Table rowKey="id" dataSource={smo} columns={columns} loading={SMOisFetching} />
      <Title level={4}>{t('FRONT_SMO_TRANSLATIONS')}</Title>
      <Table
        rowKey="id"
        dataSource={translations}
        columns={columns2}
        loading={translationsIsFetching}
      />
    </>
  );
}

export default FinalSMO;
