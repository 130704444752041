/* eslint-disable react/prop-types */
import DateFnsUtils from '@date-io/date-fns';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { InboxOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import {
  Button,
  Form,
  Input,
  message,
  Radio,
  Select,
  Skeleton,
  Upload,
  Modal,
  Row,
  Col,
} from 'antd';
import Title from 'antd/lib/typography/Title';
import React, { useCallback, useEffect, useRef, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import useLocaleDate from '../../hooks/useLocaleDate';
import { fetchDocuments } from '../../redux/documents/actions';
import { useFileTypes } from '../../redux/metadata/selectors';
import { useStep } from '../../redux/step/selectors';
import { useWorkflowUser } from '../../redux/workflowUser/selectors';
import { fetchAllFileDemands } from '../../services/api';
import FormErrors from '../form/FormErrors';

const styles = {
  macos: {
    backgroundImage: 'url(/compress_macos.jpg)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    backgroundPosition: 'center center',
    display: 'block',
    position: 'relative',
    minHeight: 250,
    width: '75%',
    maxWidth: 520,
  },
  windows: {
    backgroundImage: 'url(/compress_windows.jpg)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    backgroundPosition: 'center center',
    display: 'block',
    position: 'relative',
    minHeight: 250,
    width: '75%',
    maxWidth: 520,
  },
};

const { Dragger } = Upload;

// function for window dimensions https://stackoverflow.com/questions/36862334/get-viewport-window-height-in-reactjs
function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

// hook using the function above
function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}

function DocumentCreate({ reference, onSave, isAdmin, zipTutorial = false }) {
  const { t } = useTranslation();
  const ref = useRef();
  const [err, setErr] = useState(null);
  const [[askDocuments, isFetching], setAskDocuments] = useState([[], !isAdmin]);
  const [isPostal, setIsPostal] = useState(false);
  const [isZipTutorialOpen, setIsZipTutorialOpen] = useState(false);
  const [workflow] = useWorkflowUser();
  const [step] = useStep() || [];

  const localeDate = useLocaleDate();
  const dispatch = useDispatch();
  const idsFileTypes = useFileTypes('administrative');

  const { height, width } = useWindowDimensions();

  const loadFilesDemands = useCallback(async () => {
    fetchAllFileDemands(reference)
      .then((s) =>
        setAskDocuments([
          s.map((m) => ({ alias: m.filetype, id: m.file_type_id, description: m.description })),
          false,
        ])
      )
      .catch(() => setAskDocuments([[], false]));
  }, [reference]);

  const showZipTutorial = () => {
    setIsZipTutorialOpen(true);
  };

  const hideZipTutorial = () => {
    setIsZipTutorialOpen(false);
  };

  useEffect(() => {
    if (!isAdmin) {
      loadFilesDemands();
    }
  }, [isAdmin, loadFilesDemands]);

  const fileTypes = useMemo(() => {
    let ft = [];
    if (isAdmin) {
      if (reference) {
        ft = step?.requirements?.data || [];
      } else {
        ft = workflow?.requirements || [];
      }
    } else {
      ft = askDocuments;
    }
    return ft;
  }, [askDocuments, reference, workflow, isAdmin, step]);

  if (isFetching) return <Skeleton />;

  return (
    <>
      <Title level={5} style={{ marginTop: 10 }}>
        {t('FRONT_DOCUMENT_UPLOAD')}
      </Title>
      <Form
        ref={ref}
        layout="vertical"
        onFinish={async (values) => {
          try {
            setErr(null);
            await onSave({ ...values });
            setAskDocuments([askDocuments.filter((a) => a.id !== values.file_type_id), false]);
            message.success(t('FRONT_NOTIFICATION_UPLOAD_SUCCESS'));
            if (ref.current) ref.current.resetFields();
          } catch (error) {
            dispatch(fetchDocuments(reference));
            message.error(t('FRONT_NOTIFICATION_UPLOAD_FAILED'));
            setErr(error);
          }
        }}
        initialValues={{ postal: isPostal }}
      >
        {!isAdmin && (
          <Form.Item name="postal" label={t('FRONT_DOCUMENT_SELECT_WAY')}>
            <Radio.Group>
              <Radio value={false} onChange={() => setIsPostal(false)}>
                {t('FRONT_DOCUMENT_WAY_UPLOAD')}
              </Radio>
              <Radio value onChange={() => setIsPostal(true)}>
                {t('FRONT_DOCUMENT_WAY_POSTAL')}
              </Radio>
            </Radio.Group>
          </Form.Item>
        )}

        <Form.Item
          name="file_type_id"
          label={t('FRONT_DOCUMENT_SELECT_TYPE')}
          rules={[{ required: true, message: t('FRONT_FORM_MESSAGE_REQUIRED') }]}
        >
          <Select placeholder="Select">
            {fileTypes.map((f) => (
              <Select.Option key={f.id} value={f.id}>
                {f.alias} {askDocuments && f.description && `(${f.description})`}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        {!isAdmin && (
          <Form.Item
            name="name"
            label={t('FRONT_DOCUMENT_NAME')}
            rules={[{ required: true, message: t('FRONT_FORM_MESSAGE_REQUIRED') }]}
          >
            <Input />
          </Form.Item>
        )}

        {isPostal && (
          <>
            <Form.Item name="postal_type" label={t('FRONT_DOCUMENT_POSTAL_TYPE')}>
              <Input />
            </Form.Item>
            <Form.Item name="postal_number" label={t('FRONT_DOCUMENT_POSTAL_NUMBER')}>
              <Input />
            </Form.Item>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={localeDate}>
              <Form.Item
                label={t('FRONT_DOCUMENT_POSTAL_DATE')}
                name="postal_date"
                rules={[{ required: true, message: t('FRONT_FORM_MESSAGE_REQUIRED') }]}
              >
                <KeyboardDatePicker margin="none" id="date-picker-dialog" format="dd/MM/yyyy" />
              </Form.Item>
            </MuiPickersUtilsProvider>
          </>
        )}

        {!isPostal && (
          <>
            <Form.Item
              name="file"
              rules={[{ required: true, message: t('FRONT_FORM_MESSAGE_REQUIRED') }]}
            >
              <Dragger beforeUpload={() => false} multiple>
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">{t('FRONT_DOCUMENT_FILE_UPLOAD')}</p>
              </Dragger>
            </Form.Item>
          </>
        )}

        <FormErrors err={err} />

        <Form.Item>
          <Button type="primary" htmlType="submit">
            {t('FRONT_FORM_SAVE')}
          </Button>
          {!isPostal && zipTutorial && (
            <Button
              style={{ marginLeft: 10 }}
              icon={<QuestionCircleOutlined />}
              onClick={showZipTutorial}
            >
              {t('FRONT_MEDICAL_ARCHIVE_HELP_BUTTON')}
            </Button>
          )}
        </Form.Item>
      </Form>
      {zipTutorial && (
        <Modal
          title={t('FRONT_MEDICAL_ARCHIVE_TUTORIAL_TITLE')}
          width={width * 0.9}
          footer={null}
          visible={isZipTutorialOpen}
          onOk={hideZipTutorial}
          onCancel={hideZipTutorial}
        >
          <Row>
            <Col span={24}>
              <p>{t('FRONT_MEDICAL_ARCHIVE_TUTORIAL_INTRO')}</p>
            </Col>
          </Row>
          <Row justify="space-between">
            <Col span={10}>
              <Title level={5}>{t('FRONT_MEDICAL_ARCHIVE_TUTORIAL_SUBTITLE_WINDOWS')}</Title>
              <div style={styles.windows} />
              <p>{t('FRONT_MEDICAL_ARCHIVE_TUTORIAL_TEXT_WINDOWS')}</p>
            </Col>
            <Col span={10}>
              <Title level={5}>{t('FRONT_MEDICAL_ARCHIVE_TUTORIAL_SUBTITLE_MACOS')}</Title>
              <div style={styles.macos} /> <p>{t('FRONT_MEDICAL_ARCHIVE_TUTORIAL_TEXT_MACOS')}</p>
            </Col>
          </Row>
        </Modal>
      )}
    </>
  );
}

export default DocumentCreate;
