import React, { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Workflow from '..';
import { useMedicalFile } from '../../../redux/medicalFile/selectors';
import { fetchStep } from '../../../redux/step/actions';
import { useStep } from '../../../redux/step/selectors';
import Loading from '../../layouts/Loading';
import SMOSTEPS from './SMO.steps';

function SMOWorflow() {
  const dispatch = useDispatch();
  const history = useHistory();
  const [, isFetching] = useStep();
  const [medicalFile] = useMedicalFile();

  useEffect(() => {
    dispatch(fetchStep(medicalFile.reference));
  }, [dispatch, medicalFile.reference]);

  const steps = useMemo(
    () => [
      {
        reference: SMOSTEPS.ASSIGN_TO_LOCAL_COORDINATOR,
        next: () => history.replace(`/medicals/${medicalFile.reference}`, { edit: true }),
        autoNext: true,
      },
      {
        reference: SMOSTEPS.FILL_CONTACT,
        next: () => history.push(`/medicals/${medicalFile.reference}/documents_admin`),
        shouldValidate: true,
      },
      {
        reference: SMOSTEPS.UPLOAD_ADMIN_DOCUMENTS,
        next: () => history.push(`/medicals/${medicalFile.reference}/documents_admin`),
      },
      {
        reference: SMOSTEPS.FILL_MEDICAL_FORM,
        next: () => history.push(`/medicals/${medicalFile.reference}/health`),
        autoNext: true,
        reloader: 'Local Coordinator',
      },
      {
        reference: SMOSTEPS.ASSIGN_TO_LOCAL_DOCTOR,
        next: () => history.replace(`/medicals/${medicalFile.reference}`, { edit: true }),
        autoNext: true,
      },
      {
        reference: SMOSTEPS.WAITING_FOR_INTERVIEW,
        next: () => dispatch(fetchStep(medicalFile.reference, true)),
      },
      {
        reference: SMOSTEPS.ASK_MEDICAL_FILES,
        next: () => history.push(`/medicals/${medicalFile.reference}/ask`),
        shouldValidate: true,
      },
      {
        reference: SMOSTEPS.UPLOAD_MEDICAL_FILES,
        next: () => history.push(`/medicals/${medicalFile.reference}/documents`),
        reloader: 'Local Doctor',
      },
      {
        reference: SMOSTEPS.LD_VALIDATE_MEDICAL_FILE,
        next: async () => history.push(`/medicals/${medicalFile.reference}/documents`),
        shouldValidate: true,
      },
      {
        reference: SMOSTEPS.ASSIGN_TO_HUB_COORDINATOR,
        next: () => history.replace(`/medicals/${medicalFile.reference}`, { edit: true }),
        autoNext: true,
      },
      {
        reference: SMOSTEPS.ASSIGN_TO_HUB_DOCTOR,
        next: () => history.replace(`/medicals/${medicalFile.reference}`, { edit: true }),
        autoNext: true,
      },
      {
        reference: SMOSTEPS.HD_VALIDATE_MEDICAL_FILE,
        next: () => history.replace(`/medicals/${medicalFile.reference}`, { edit: true }),
        shouldValidate: true,
      },
      {
        reference: SMOSTEPS.ASSIGN_TO_EXPERT_COORDINATOR,
        next: () => history.replace(`/medicals/${medicalFile.reference}`, { edit: true }),
        autoNext: true,
      },
      {
        reference: SMOSTEPS.ASSIGN_TO_EXPERT_DOCTOR,
        next: () => history.replace(`/medicals/${medicalFile.reference}`, { edit: true }),
        autoNext: true,
      },
      {
        reference: SMOSTEPS.INITIATE_SMO,
        next: () => history.replace(`/medicals/${medicalFile.reference}/smo`),
        autoNext: true,
      },
      {
        reference: SMOSTEPS.DO_SMO,
        next: () => {
          dispatch(fetchStep(medicalFile.reference, true));
          history.push(`/medicals/${medicalFile.reference}/smo`);
        },
      },
      {
        reference: SMOSTEPS.HD_VALIDATE_SMO,
        next: () => history.push(`/medicals/${medicalFile.reference}/smo`),
        shouldValidate: true,
      },
      {
        reference: SMOSTEPS.LD_VALIDATE_SMO,
        next: () => history.push(`/medicals/${medicalFile.reference}/smo`),
        shouldValidate: true,
      },
      {
        reference: SMOSTEPS.VIEW_SMO,
        next: () => {
          dispatch(fetchStep(medicalFile.reference, true));
          history.push(`/medicals/${medicalFile.reference}/smo/final`);
        },
        shouldValidate: true,
        reloader: 'Local Doctor',
      },
      {
        reference: SMOSTEPS.FILE_CLOSED,
        next: () => history.push(`/medicals/${medicalFile.reference}/satisfactions`),
        autoNext: true,
      },
    ],
    [history, medicalFile, dispatch]
  );
  if (isFetching) return <Loading />;
  return <Workflow steps={steps} />;
}

export default SMOWorflow;
